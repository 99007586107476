import { useState } from "react";
import { ILastSectionContainerProps } from "./LastSection.interfaces";
import LastSectionView from "./LastSection.view";

export default function LastSectionContainer(
    props: ILastSectionContainerProps
) {
    const [showDemo, setShowDemo] = useState(false);
    const demoController = () => setShowDemo(!showDemo);

    return (
        <>
            <LastSectionView
                demoController={demoController}
                showDemo={showDemo}
            />
        </>
    );
}
