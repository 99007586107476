import Navigation from "../Navigation";
import { IVrAutoViewProps } from "./VrAuto.interfaces";
import BookDemo from "../BookDemo";
import UserLoginBox from "../UserLoginBox";
import { Link } from "react-router-dom";
export default function VrAutoView(props: IVrAutoViewProps) {
    return (
        <>
            {props.showlogin === true && (
                <UserLoginBox
                    redirectionURL="/metaverse/65b741def8309762720ad53f"
                    loginController={props.loginController}
                /> // Since the line 122, says Login to Eternity City, but can be changed later => TP | 2024-01-30 14:39:44
            )}
            {props.showDemo === true && (
                <BookDemo demoController={props.demoController} />
            )}
            <div className="font-['roboto']">
                <section className="relative h-auto">
                    <video
                        autoPlay
                        muted
                        loop
                        playsInline
                        className="absolute top-0 left-0 w-full h-full object-cover"
                    >
                        <source
                            src="../images/vrauto/background.webm"
                            type="video/webm"
                        />
                        Your browser does not support the video tag.
                    </video>
                    <div className="absolute inset-0 bg-black opacity-50"></div>
                    <Navigation />
                    <div className="relative z-10 flex flex-col items-center justify-center h-full text-white">
                        <div className="text-left max-w-screen-2xl mx-auto max-md:px-2">
                            <div className="text-center py-72 max-md:py-48">
                                <div className="mx-20 max-md:m-2 max-md:text-center">
                                    <h3 className="my-10 max-md:mt-10 max-md:mb-5 text-5xl max-md:text-2xl text-white">
                                        <span className="text-[#136CC5] bg-[#136CC5]/[0.30] shadow-inner">
                                            VR Auto
                                        </span>{" "}
                                        : Revolutionizing Automotive Retail
                                    </h3>
                                    <p className="mb-20 max-md:mb-2 text-white mx-20 max-md:mx-2 max-md:text-xs text-[18px]">
                                        Welcome to VRAuto, where automotive
                                        retail meets the future. We're excited
                                        to introduce you to a revolutionary way
                                        of experiencing and buying vehicles.
                                        Step into the virtual showroom of VRAuto
                                        and discover a new era of automotive
                                        retail that's immersive, convenient, and
                                        innovative.
                                    </p>
                                    <Link
                                        to="https://dashboard.invincibleocean.com/dashboard"
                                        target="_blank"
                                    >
                                        <button
                                            // onClick={props.loginController}
                                            className="border-2 bg-[#FFF8F840] text-white max-md:mt-2 transition duration-300 ease-in-out hover:scale-[1.05] hover:bg-[#FFF8F860] px-8 py-3 rounded-md"
                                        >
                                            Login to Dashboard
                                            <i className="fa fa-long-arrow-right ml-4"></i>
                                        </button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="bg-white max-w-screen-2xl mx-auto max-md:px-2 my-14 text-left">
                    <h1 className="text-4xl font-bold text-center text-[#1C2347]">
                        Why VRAuto?
                    </h1>
                    <p className="text-center py-5 text-[#1C2347] font-['roboto'] font-medium">
                        Personalize your vehicle's specifications, colors, and
                        features to match your preferences
                    </p>
                    <div className="grid grid-cols-2 max-md:grid-cols-1 max-md:my-5 my-10">
                        <div>
                            <img src="./images/vrauto/showroom.webp" alt="" />
                        </div>
                        <div className="p-14 max-md:p-2">
                            <h5 className="text-2xl font-bold text-[#1C2347] py-5">
                                Stunning Virtual Showrooms
                            </h5>
                            <p className="font-['roboto'] text-[#6B6B6B] font-medium">
                                Say goodbye to traditional showrooms. VRAuto
                                transports you into captivating virtual
                                showrooms where you can explore the latest
                                vehicles in intricate 3D detail.
                            </p>
                            <button
                                onClick={props.demoController}
                                className="mt-10 bg-blue-100 font-[700] px-10 py-3 text-white transition duration-300 ease-in-out hover:scale-[1.05] hover:bg-gradient-to-l rounded-md mr-4 bg-gradient-to-r from-blue-500 to-purple-600"
                            >
                                Book a Demo
                            </button>
                        </div>
                    </div>
                    <div className="grid grid-cols-2 max-md:grid-cols-1 max-md:my-5 my-10">
                        <div className="p-14 max-md:p-2">
                            <h5 className="text-2xl font-bold text-[#1C2347] py-5">
                                Interactive Vehicle Exploration
                            </h5>
                            <p className="font-['roboto'] text-[#6B6B6B] font-medium">
                                Get up close and personal with the vehicles.
                                Interact with them as if you were physically
                                present, examining every angle and feature.
                            </p>
                            <button
                                onClick={props.demoController}
                                className="mt-10 bg-blue-100 font-[700] px-10 py-3 text-white transition duration-300 ease-in-out hover:scale-[1.05] hover:bg-gradient-to-l rounded-md mr-4 bg-gradient-to-r from-blue-500 to-purple-600"
                            >
                                Book a Demo
                            </button>
                        </div>
                        <div>
                            <img
                                src="./images/vrauto/vehicleexplore.webp"
                                alt=""
                            />
                        </div>
                    </div>
                    <div className="grid grid-cols-2 max-md:grid-cols-1 max-md:my-5 my-10">
                        <div>
                            <img src="./images/vrauto/testdrive.webp" alt="" />
                        </div>
                        <div className="p-14 max-md:p-2">
                            <h5 className="text-2xl font-bold text-[#1C2347] py-5">
                                Effortless Test Drives
                            </h5>
                            <p className="font-['roboto'] text-[#6B6B6B] font-medium">
                                Take virtual test drives without leaving your
                                home. Experience the thrill of driving your
                                dream car from the comfort of your VR headset.
                            </p>
                            <button
                                onClick={props.demoController}
                                className="mt-10 bg-blue-100 font-[700] px-10 py-3 text-white transition duration-300 ease-in-out hover:scale-[1.05] hover:bg-gradient-to-l rounded-md mr-4 bg-gradient-to-r from-blue-500 to-purple-600"
                            >
                                Book a Demo
                            </button>
                        </div>
                    </div>
                </section>
                <section
                    className="bg-cover"
                    style={{
                        backgroundImage: `url('./images/vrauto/backdrop2.webp')`,
                    }}
                >
                    <div className="bg-[#030303B0] text-left">
                        <div className="max-w-screen-2xl mx-auto max-md:px-2 py-20 max-md:py-0">
                            <div className="text-center">
                                <h3 className="text-white text-4xl pt-10">
                                    Your Virtual Showroom Experience
                                </h3>
                                <p className="mx-48 my-5 font-['roboto'] text-white">
                                    With VRAuto, you'll experience
                                </p>
                            </div>
                            <div className="grid xl:grid-cols-3 md:grid-cols-2 grid-cols-1">
                                <div className="div-cards-vrauto">
                                    <img
                                        alt=""
                                        src="./images/vrauto/logos/l1.svg"
                                    />
                                    <h5>Virtual Showroom Tours</h5>
                                    <p>
                                        Explore virtual showrooms filled with
                                        the latest models from top automotive
                                        brands.
                                    </p>
                                </div>
                                <div className="div-cards-vrauto">
                                    <img
                                        src="./images/vrauto/logos/l2.svg"
                                        alt=""
                                    />
                                    <h5>Virtual Test Drives</h5>
                                    <p>
                                        Feel the excitement of test driving
                                        multiple vehicles virtually to find your
                                        perfect match.
                                    </p>
                                </div>
                                <div className="div-cards-vrauto">
                                    <img
                                        src="./images/vrauto/logos/l3.svg"
                                        alt=""
                                    />
                                    <h5>Customization</h5>
                                    <p>
                                        Feel the excitement of test driving
                                        multiple vehicles virtually to find your
                                        perfect match.
                                    </p>
                                </div>
                                <div className="div-cards-vrauto">
                                    <img
                                        src="./images/vrauto/logos/l4.svg"
                                        alt=""
                                    />
                                    <h5>Instant Information</h5>
                                    <p>
                                        Access detailed information about each
                                        vehicle, including pricing, financing
                                        options, and special offers.
                                    </p>
                                </div>
                                <div className="div-cards-vrauto">
                                    <img
                                        src="./images/vrauto/logos/l5.svg"
                                        alt=""
                                    />
                                    <h5>Live Chat Support</h5>
                                    <p>
                                        Chat with virtual sales representatives
                                        for real-time assistance and guidance.
                                    </p>
                                </div>
                                <div className="div-cards-vrauto">
                                    <img
                                        src="./images/vrauto/logos/l6.svg"
                                        alt=""
                                    />
                                    <h5>Order and Delivery</h5>
                                    <p>
                                        Place your order directly from the
                                        virtual showroom, and schedule delivery
                                        to your doorstep.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="max-w-screen-2xl mx-auto max-md:px-2">
                    <div className="grid grid-cols-2 max-md:grid-cols-1 my-10 max-md:my-5">
                        <div>
                            <img src="./images/vrauto/automobile.webp" alt="" />
                        </div>
                        <div className="p-14 max-md:p-7">
                            <h5 className="text-4xl font-bold text-[#1C2347] py-5">
                                Join the Automotive Retail Revolution
                            </h5>
                            <p className="font-['roboto'] text-[#6B6B6B] font-medium">
                                VR Auto is more than a virtual showroom, It is
                                an automotive retail experience that is
                                redefining how you can explore and purchase
                                vehicles. Join us on this exciting journey into
                                the virtual automotive world, where convenience
                                and informed decision-making are our top
                                priorities.
                            </p>
                            <button
                                onClick={props.demoController}
                                className="mt-10 bg-blue-100 font-[700] px-10 py-3 text-white transition duration-300 ease-in-out hover:scale-[1.05] hover:bg-gradient-to-l rounded-md mr-4 bg-gradient-to-r from-blue-500 to-purple-600"
                            >
                                Book a Demo
                            </button>
                        </div>
                    </div>
                </section>
                <section
                    className="bg-cover p-24 max-md:p-10 text-center"
                    style={{
                        backgroundImage: `url('./images/vrauto/backdrop.webp')`,
                    }}
                >
                    <div className="bg-[#030303B0] text-white max-w-screen-2xl mx-auto max-md:px-2 p-24 max-md:p-4">
                        <h3 className="text-3xl px-24 max-xl:px-0">
                            Ready to Explore VRAuto?
                        </h3>
                        <p className="p-10 px-36 max-xl:px-2 max-xl:py-5 font-['roboto']">
                            Take the first step into the future of automotive
                            retail with VRAuto. Start your virtual showroom tour
                            today and experience automotive shopping like never
                            before. Your automotive retail revolution begins
                            here, at VRAuto.
                        </p>

                        <button
                            onClick={props.loginController}
                            className="border-2 bg-[#FFF8F840] text-white max-md:mt-2 transition duration-300 ease-in-out hover:scale-[1.05] hover:bg-[#FFF8F860] px-8 py-3 rounded-md"
                        >
                            Login to Eternity City
                            <i className="fa fa-long-arrow-right ml-4"></i>
                        </button>
                    </div>
                </section>
            </div>
        </>
    );
}
