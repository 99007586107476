import SuperAdminBookedDemosView from "./SuperAdminBookedDemos.view";
import { SuperAdminBookedDemosContainerProps } from "./SuperAdminBookedDemos.interface";
import { useEffect, useState } from "react";
// import { MetaverseUserService } from "../../Services";

export default function SuperAdminBookedDemosContainer(
    props: SuperAdminBookedDemosContainerProps
) {
    const [users, setUsers] = useState([]);
    const itemsPerPage = 10;
    const [currentPage, setCurrentPage] = useState(1);
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const handleNextPage = () => {
        setCurrentPage((prevPage) => prevPage + 1);
    };

    const handlePrevPage = () => {
        setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
    };
    useEffect(() => {
        // async function fetchMetaverseUsers() {
        //     const _users = await MetaverseUserService.fetchMany();
        //     setUsers(_users?.data?.users ?? []);
        // }
        // fetchMetaverseUsers();
    }, []);

    return (
        <div>
            <SuperAdminBookedDemosView
                users={users}
                startIndex={startIndex}
                endIndex={endIndex}
                handleNextPage={handleNextPage}
                handlePrevPage={handlePrevPage}
                currentPage={currentPage}
            />
        </div>
    );
}
