import { IMessageViewProps } from "./Message.interfaces";

export default function MessageView(props: IMessageViewProps) {
    return (
        <>
            {props.messageText && (
                <div className="message-container">
                    {props.messageClass === "error" && (
                        <div className="error-message-box">
                            <div>
                                <i className="fa fa-times-circle text-red text-[32px]"></i>
                                <div>
                                    <h4>Error</h4>
                                    <h5>{props.messageText}</h5>
                                </div>
                                <p onClick={() => props.demoController()}>
                                    Close
                                </p>
                            </div>
                        </div>
                    )}
                    {props.messageClass === "success" && (
                        <div className="success-message-box">
                            <div>
                                <i className="fa fa-check-circle text-green text-[32px]"></i>
                                <div>
                                    <h4>Success</h4>
                                    <h5>{props.messageText}</h5>
                                </div>
                                <p onClick={() => props.demoController()}>
                                    Close
                                </p>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </>
    );
}
