import { ILastSectionViewProps } from "./LastSection.interfaces";
import BookDemo from "../BookDemo";
export default function LastSectionView(props: ILastSectionViewProps) {
    return (
        <>
            {props.showDemo === true && (
                <BookDemo demoController={props.demoController} />
            )}
            <section className="bg-gradient-to-b from-[#6091E2]  to-[#340089] p-24 max-md:p-10 text-center">
                <div className="bg-gradient-to-b from-[#0B0B0BAB] via-[#3D324391] to-[#0B0B0BAB] rounded-xl border-[1px] border-white text-white max-w-screen-2xl mx-auto max-md:px-2 p-24 max-md:p-4">
                    <h3 className="text-3xl px-24 max-xl:px-0">
                        Ready to Get Started?
                    </h3>
                    <p className="py-10 px-36 max-xl:px-2 max-xl:py-5 font-['roboto']">
                        Transform your operations with innovative, AI-driven
                        solutions tailored to your needs. Enhance efficiency,
                        safety, and performance like never before. Contact us
                        today to revolutionize your industry!
                    </p>

                    <button
                        onClick={props.demoController}
                        className="border-2 bg-[#FFF8F840] text-white max-md:mt-2 transition duration-300 ease-in-out hover:scale-[1.05] hover:bg-[#FFF8F860] px-8 py-3 rounded-md"
                    >
                        Book a Demo
                        <i className="fa fa-long-arrow-right ml-4"></i>
                    </button>
                </div>
            </section>
        </>
    );
}
