import { useEffect, useState } from "react";
import { IHealthCareContainerProps } from "./HealthCare.interfaces";
import HealthCareView from "./HealthCare.view";
import BroswerService from "../../Services/Browser.service";
export default function HealthCareContainer(props: IHealthCareContainerProps) {
    useEffect(() => {
        BroswerService.setCustomTitle("HealthCareContainer");
    }, []);
    const [showDemo, setShowDemo] = useState(false);
    const demoController = () => setShowDemo(!showDemo);
    const [showlogin, setShowlogin] = useState(false);
    const loginController = () => setShowlogin(!showlogin);
    return (
        <>
            <HealthCareView
                demoController={demoController}
                showDemo={showDemo}
                loginController={loginController}
                showlogin={showlogin}
            />
        </>
    );
}
