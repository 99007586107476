import BookDemo from "../BookDemo";
import Navigation from "../Navigation";
import { IIndustriesViewProps } from "./Industries.interfaces";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const responsive = {
    superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 3,
        partialVisibilityGutter: 20,
    },
    desktop: {
        breakpoint: { max: 3000, min: 1388 },
        items: 3,
        partialVisibilityGutter: 10,
    },
    tablet: {
        breakpoint: { max: 1388, min: 687 },
        items: 2,
        partialVisibilityGutter: 30,
    },
    mobile: {
        breakpoint: { max: 687, min: 0 },
        items: 1,
        partialVisibilityGutter: 10,
    },
};
export default function IndustriesView(props: IIndustriesViewProps) {
    return (
        <>
            {props.showDemo === true && (
                <BookDemo demoController={props.demoController} />
            )}
            <div className="font-['roboto'] text-left">
                <div>
                    <div
                        className="text-white bg-cover bg-center"
                        style={{
                            backgroundImage: `url('./images/industries/backdrop.webp')`,
                        }}
                    >
                        <div className="bg-black/[0.50]">
                            <Navigation />
                            <div className="px-10 py-28 max-md:py-20">
                                <div className="max-w-screen-2xl mx-auto max-md:px-2">
                                    <div className="">
                                        <div>
                                            <h3 className="text-white text-center pt-48 pb-14 font-['roboto'] text-[58px] leading-[68px] max-sm:text-4xl font-bold my-5">
                                                Revolutionizing industries with
                                                Invincible Meta
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bg-[#136CC530]">
                    <div className="max-w-screen-2xl mx-auto max-md:px-2 flex justify-center">
                        <button
                            onClick={() => props.scrollToTarget("Education")}
                            className="cursor-pointer px-10 max-md:px-2 font-[400] text-[22px] leading-[26px] text-[#000000] hover:bg-[#81C0FFC4] py-5 max-md:py-2 w-[300px] max-md:w-auto max-md:text-[16px] max-[348px]:text-[14px] text-center"
                        >
                            Education
                        </button>
                        <button
                            onClick={() => props.scrollToTarget("Retail")}
                            className="cursor-pointer px-10 max-md:px-2 font-[400] text-[22px] leading-[26px] text-[#000000] hover:bg-[#81C0FFC4] py-5 max-md:py-2 w-[300px] max-md:w-auto max-md:text-[16px] max-[348px]:text-[14px] text-center"
                        >
                            Retail
                        </button>
                        <button
                            onClick={() => props.scrollToTarget("Banking")}
                            className="cursor-pointer px-10 max-md:px-2 font-[400] text-[22px] leading-[26px] text-[#000000] hover:bg-[#81C0FFC4] py-5 max-md:py-2 w-[300px] max-md:w-auto max-md:text-[16px] max-[348px]:text-[14px] text-center"
                        >
                            Banking & Healthcare
                        </button>
                        {/* <button
                            onClick={() =>
                                props.scrollToTarget("Manufacturing")
                            }
                            className="cursor-pointer px-10 max-md:px-2 font-[400] text-[22px] leading-[26px] text-[#000000] hover:bg-[#81C0FFC4] py-5 max-md:py-2 w-[200px] max-md:w-auto max-md:text-[16px] max-[348px]:text-[14px] text-center"
                        >
                            Manufacturing
                        </button> */}
                    </div>
                </div>
                <div
                    className="bg-cover"
                    style={{
                        backgroundImage: `url('./images/industries/backdrop2.webp')`,
                    }}
                    id="Finance"
                >
                    <div className="max-w-screen-2xl mx-auto max-md:px-2">
                        <h3 className="font-[600] text-[38px] leading-[45px] text-[#1C2347] text-center pt-20 pb-4">
                            Our AI-Powered Offerings
                        </h3>
                        <p className="text-center text-[22px] leading-[26px] font-[600] pb-20">
                            <span
                                onClick={props.demoController}
                                className="bg-gradient-to-r from-blue-500 to-purple-600 inline-block text-transparent bg-clip-text cursor-pointer font-[700]"
                            >
                                Book a demo{" "}
                                <i className="fa fa-angle-right font-[700] text-[22px]"></i>
                            </span>
                        </p>
                    </div>
                    <div className="pb-20 max-w-screen-2xl mx-auto">
                        <Carousel
                            containerClass="carousel-container"
                            responsive={responsive}
                            partialVisible={true}
                            swipeable={true}
                            draggable={true}
                            showDots={true}
                            ssr={true}
                            infinite={true}
                            autoPlay={true}
                            autoPlaySpeed={2000}
                            keyBoardControl={true}
                            removeArrowOnDeviceType={[
                                "desktop",
                                "laptop",
                                "tablet",
                                "mobile",
                            ]}
                        >
                            <div className="bg-white shadow-2xl rounded-lg max-md:max-w-[300px] h-[300px] mr-10 max-lg:mr-2 hover:scale-110 duration-500 my-10 mx-4 max-md:mx-10">
                                <img
                                    className="px-4 h-[100px] pt-2"
                                    alt=""
                                    src="./images/industries/icon1.webp"
                                />
                                <h3 className="font-[600] text-[#1C2347] text-[18px] xl:text-[24px] max-lg:text-[16px] leading-[28px] px-4 py-2 max-lg:py-1">
                                    AI-Driven Product Development
                                </h3>
                                <ul className="text-[#6B6B6B] text-[16px] xl:text-[18px] max-lg:text-[12px] font-[400] leading-[25px] px-4 pb-2 max-lg:pb-1 list-disc ml-4">
                                    <li>
                                        Virtual assistants for 24/7 customer
                                        engagement{" "}
                                    </li>
                                    <li>
                                        Predictive analytics for strategic
                                        decision-making
                                    </li>
                                    <li>
                                        Smart recommendation engines for
                                        personalized experiences
                                    </li>
                                </ul>
                            </div>
                            <div className="bg-white shadow-2xl rounded-lg max-md:max-w-[300px] h-[300px] mr-10 max-lg:mr-2 hover:scale-110 duration-500 my-10 mx-4 max-md:mx-10">
                                <img
                                    className="px-4 h-[100px] pt-2"
                                    alt=""
                                    src="./images/industries/icon2.webp"
                                />
                                <h3 className="font-[600] text-[#1C2347] text-[18px] xl:text-[24px] max-lg:text-[16px] leading-[28px] px-4 py-2 max-lg:py-1">
                                    Machine Learning Applications
                                </h3>
                                <ul className="text-[#6B6B6B] text-[16px] xl:text-[18px] max-lg:text-[12px] font-[400] leading-[25px] px-4 pb-2 max-lg:pb-1  list-disc ml-4">
                                    <li>Fraud detection and prevention</li>
                                    <li>Customer lifetime value prediction</li>
                                    <li>Real-time anomaly detection</li>
                                </ul>
                            </div>
                            <div className="bg-white shadow-2xl rounded-lg max-md:max-w-[300px] h-[300px] hover:scale-110 duration-500 my-10 mx-4 max-md:mx-10">
                                <img
                                    className="px-4 h-[100px] pt-2"
                                    alt=""
                                    src="./images/industries/icon3.webp"
                                />
                                <h3 className="font-[600] text-[#1C2347] text-[18px] xl:text-[24px] max-lg:text-[16px] leading-[28px] px-4 py-2 max-lg:py-1">
                                    Virtual Investment Scenarios
                                </h3>
                                <ul className="text-[#6B6B6B] text-[16px] xl:text-[18px] max-lg:text-[12px] font-[400] leading-[25px] px-4 pb-2 max-lg:pb-1  list-disc ml-4">
                                    <li>
                                        Sentiment analysis for customer insights
                                    </li>
                                    <li>
                                        Automated chatbots for seamless
                                        communication
                                    </li>
                                    <li>
                                        Speech-to-text and text-to-speech tools
                                    </li>
                                </ul>
                            </div>
                        </Carousel>
                    </div>
                </div>
                <div
                    id="Education"
                    className="bg-cover bg-center max-lg:bg-bottom py-10"
                    style={{
                        backgroundImage: `url('./images/industries/education1.webp')`,
                    }}
                >
                    <div className="max-w-screen-2xl mx-auto max-md:px-2 text-[#FFFFFF]">
                        <h3 className="font-[600] text-[38px] leading-[45px] text-[#FFFFFF] text-center pt-20 pb-4">
                            Education
                        </h3>
                        <p className="text-center text-[22px] leading-[26px] font-[600] pb-20">
                            <span
                                onClick={props.demoController}
                                className="bg-gradient-to-r from-blue-500 to-purple-600 inline-block text-transparent bg-clip-text cursor-pointer font-[700]"
                            >
                                Book a demo{" "}
                                <i className="fa fa-angle-right font-[700] text-[22px]"></i>
                            </span>
                        </p>
                        <div className="py-10">
                            <div className="grid grid-cols-2 max-md:block">
                                <div className="py-5 rounded-lg shadow-xl bg-[#FFFFFF14] px-16 max-md:px-2">
                                    <h3 className="font-[600] text-[24px] leading-[29px] pt-7">
                                        Personalized Learning Paths
                                    </h3>
                                    <p className="font-[400] text-[18px] leading-[25px] text-[#C6C6C6] py-4">
                                        AI tools adapt content to each student’s
                                        pace.
                                    </p>
                                    <h3 className="font-[600] text-[24px] leading-[29px] pt-7">
                                        Virtual Tutors
                                    </h3>
                                    <p className="font-[400] text-[18px] leading-[25px] text-[#C6C6C6] py-4">
                                        Provide instant support with intelligent
                                        chatbots.
                                    </p>
                                    <h3 className="font-[600] text-[24px] leading-[29px] pt-7">
                                        Performance Analytics
                                    </h3>
                                    <p className="font-[400] text-[18px] leading-[25px] text-[#C6C6C6] py-4">
                                        Track student progress and identify
                                        areas of improvement.
                                    </p>
                                </div>
                                <div className="py-5"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="bg-[#136CC50F] px-2 py-10 max-md:py-4"
                    id="Retail"
                >
                    <div className="max-w-screen-2xl mx-auto max-md:px-2">
                        <h3 className="font-[600] text-[38px] leading-[45px] text-[#1C2347] text-center pt-20 pb-4">
                            Retail & E-Commerce
                        </h3>
                        <p className="text-center text-[22px] leading-[26px] font-[600] pb-20">
                            <span
                                onClick={props.demoController}
                                className="bg-gradient-to-r from-blue-500 to-purple-600 inline-block text-transparent bg-clip-text cursor-pointer font-[700]"
                            >
                                Book a demo{" "}
                                <i className="fa fa-angle-right font-[700] text-[22px]"></i>
                            </span>
                        </p>
                    </div>
                    <div className="py-10 max-w-screen-2xl mx-auto max-md:px-2 grid grid-cols-[1fr_1fr] justify-items-center max-lg:grid max-lg:grid-cols-[1fr]">
                        <div className="shadow-2xl flex rounded-lg mr-20 max-xl:mr-2 hover:scale-110 max-md:hover:scale-100 duration-500 max-lg:mb-2 max-md:w-full px-2">
                            <div className="w-2/6 max-md:w-3/6">
                                <h3 className="font-[600] text-[#1C2347] text-[18px] max-md:text-[16px] leading-[28px] px-4 py-2">
                                    Personalized Shopping
                                </h3>
                                <p className="text-[#6B6B6B] text-[14px] max-md:text-[12px] font-[400] leading-[25px] px-4 py-2">
                                    AI-powered recommendation engines tailor
                                    experiences for each customer.
                                </p>
                            </div>
                            <img
                                src="./images/industries/retail2.webp"
                                alt=""
                                className="w-4/6 max-md:w-3/6 object-cover rounded-r-xl"
                            />
                        </div>
                        <div className="shadow-2xl flex rounded-lg hover:scale-110 max-md:hover:scale-100 duration-500 max-md:w-full px-2">
                            <div className="w-2/6 max-md:w-3/6">
                                <h3 className="font-[600] text-[#1C2347] text-[18px] max-md:text-[16px] leading-[28px] px-4 py-2">
                                    Virtual Try-Ons
                                </h3>
                                <p className="text-[#6B6B6B] text-[14px] max-md:text-[12px] font-[400] leading-[25px] px-4 py-2">
                                    Enable customers to try products virtually,
                                    boosting sales.
                                </p>
                            </div>

                            <img
                                src="./images/industries/retail1.webp"
                                alt=""
                                className="w-4/6 max-md:w-3/6 object-cover rounded-r-xl"
                            />
                        </div>
                    </div>
                    <div className="shadow-2xl rounded-xl max-w-screen-2xl mx-auto max-md:px-2 hover:scale-110 max-md:hover:scale-100 duration-500">
                        <div className="flex justify-center max-md:px-2">
                            <img
                                src="./images/industries/retail3.webp"
                                alt=""
                                className="w-full"
                            />
                        </div>
                        <div className="w-full text-left">
                            <h3 className="font-[600] text-[#1C2347] text-[18px] max-md:text-[16px] leading-[28px] px-2 py-4">
                                Inventory Optimization
                            </h3>
                            <p className="text-[#6B6B6B] text-[14px] max-md:text-[12px] font-[400] leading-[25px] px-2 pb-6">
                                Predict demand and manage stock efficiently.
                            </p>
                        </div>
                    </div>
                </div>
                <div
                    id="Banking"
                    className="bg-cover bg-center max-lg:bg-bottom py-10"
                    style={{
                        backgroundImage: `url('./images/industries/secondsection.webp')`,
                    }}
                >
                    <div className="max-w-screen-2xl mx-auto max-md:px-2 text-[#FFFFFF]">
                        <h3 className="font-[600] text-[38px] leading-[45px] text-[#FFFFFF] text-center pt-20 pb-4">
                            Banking/Financial Services & Healthcare
                        </h3>
                        <p className="text-center text-[22px] leading-[26px] font-[600] pb-20">
                            <span
                                onClick={props.demoController}
                                className="bg-gradient-to-r from-blue-500 to-purple-600 inline-block text-transparent bg-clip-text cursor-pointer font-[700]"
                            >
                                Book a demo{" "}
                                <i className="fa fa-angle-right font-[700] text-[22px]"></i>
                            </span>
                        </p>
                        <div className="py-10">
                            <div className="grid grid-cols-2 max-md:block">
                                <div className="py-5 rounded-lg shadow-xl bg-[#FFFFFF14] px-16 max-md:px-2">
                                    <h3 className="font-[600] text-[24px] leading-[29px] pt-5">
                                        Fraud Prevention
                                    </h3>
                                    <p className="font-[400] text-[18px] leading-[25px] text-[#C6C6C6] py-4">
                                        AI algorithms analyze transactions for
                                        anomalies, reducing fraud.
                                    </p>
                                    <h3 className="font-[600] text-[24px] leading-[29px] pt-5">
                                        Customer Onboarding
                                    </h3>
                                    <p className="font-[400] text-[18px] leading-[25px] text-[#C6C6C6] py-4">
                                        Streamline KYC with real-time document
                                        verification.
                                    </p>
                                    <h3 className="font-[600] text-[24px] leading-[29px] pt-5">
                                        Risk Assessment
                                    </h3>
                                    <p className="font-[400] text-[18px] leading-[25px] text-[#C6C6C6] py-4">
                                        Predict creditworthiness using
                                        AI-powered analytics.
                                    </p>
                                    <h3 className="font-[600] text-[24px] leading-[29px] pt-5">
                                        AI Diagnosis Tools
                                    </h3>
                                    <p className="font-[400] text-[18px] leading-[25px] text-[#C6C6C6] py-4">
                                        Identify patterns in medical imaging for
                                        faster diagnosis.
                                    </p>
                                    <h3 className="font-[600] text-[24px] leading-[29px] pt-5">
                                        Patient Monitoring
                                    </h3>
                                    <p className="font-[400] text-[18px] leading-[25px] text-[#C6C6C6] py-4">
                                        Use wearable devices and AI to track
                                        patient health.
                                    </p>
                                    <h3 className="font-[600] text-[24px] leading-[29px] pt-5">
                                        Drug Discovery
                                    </h3>
                                    <p className="font-[400] text-[18px] leading-[25px] text-[#C6C6C6] py-4">
                                        Accelerate research with AI-driven
                                        simulations.
                                    </p>
                                </div>
                                <div className="py-5"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div
                        className="text-white bg-cover bg-center"
                        style={{
                            backgroundImage: `url('./images/industries/vehicle2.webp')`,
                        }}
                    >
                        <div className="bg-[#1370C563]">
                            <div className="px-10 py-28">
                                <div className="max-w-screen-2xl mx-auto max-md:px-2">
                                    <div className="text-center">
                                        <div>
                                            <h3 className="text-white text-center pb-14 font-['roboto'] text-[58px] leading-[68px] max-sm:text-4xl font-bold my-5">
                                                Experience Invincible Meta: A
                                                New Era of Virtual Reality
                                            </h3>
                                            <p className="pb-10 font-['roboto'] text-lg font-medium px-20 max-md:px-0">
                                                See firsthand how Invincible
                                                Meta is shaping the future of
                                                multiple industries. Our demo
                                                will provide a comprehensive
                                                overview of the possibilities
                                                and benefits of VR technology in
                                                your specific sector.
                                            </p>
                                            <button
                                                onClick={props.demoController}
                                                className="bg-blue-100 px-10 py-3 max-h-[50px] max-w-[200px] font-[600] text-white transition duration-300 ease-in-out hover:scale-[1.05] hover:bg-gradient-to-l rounded-md mr-4 bg-gradient-to-r from-blue-500 to-purple-600"
                                            >
                                                Book a Demo
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
