import Navigation from "../Navigation";
import { IPredectiveViewProps } from "./Predective.interfaces";
import BookDemo from "../BookDemo";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import data from "../ResponisveCarousel.json";
import UserLoginBox from "../UserLoginBox";
import LastSection from "../LastSection";
export default function PredectiveView(props: IPredectiveViewProps) {
    return (
        <>
            {props.showlogin === true && (
                <UserLoginBox
                    redirectionURL="/metaverse/65b741def8309762720ad53f"
                    loginController={props.loginController}
                /> // Since the line 122, says Login to Eternity City, but can be changed later => TP | 2024-01-30 14:39:44
            )}
            {props.showDemo === true && (
                <BookDemo demoController={props.demoController} />
            )}
            <div className="font-['roboto']">
                <section className="relative h-auto">
                    <video
                        autoPlay
                        muted
                        loop
                        playsInline
                        className="absolute top-0 left-0 w-full h-full object-cover"
                    >
                        <source
                            src="./images/predictive/predictiveheader.webm"
                            type="video/webm"
                        />
                        Your browser does not support the video tag.
                    </video>
                    <div className="absolute inset-0 bg-black opacity-50"></div>
                    <Navigation />
                    <div className="relative z-10 flex flex-col items-center justify-center h-full text-white">
                        <div className="text-left max-w-screen-2xl mx-auto max-md:px-2">
                            <div className="text-center py-72 max-md:py-48">
                                <div className="mx-20 max-md:m-2 max-md:text-center">
                                    <h3 className="my-10 max-md:mt-10 max-md:mb-5 text-5xl max-md:text-5xl text-white">
                                        <span className="text-white">
                                            Predictive Solutions
                                        </span>{" "}
                                    </h3>
                                    <p className="mb-20 max-md:mb-2 text-white mx-20 max-md:mx-2 max-md:text-sm text-[18px]">
                                        Stay ahead of equipment failures with
                                        AI-powered predictive analytics.
                                        Identify potential issues before they
                                        occur, minimize downtime, and extend the
                                        lifespan of your assets. Optimize
                                        performance while reducing maintenance
                                        costs.
                                    </p>
                                    <button
                                        onClick={props.demoController}
                                        className="border-2 bg-[#FFF8F840] text-white max-md:mt-2 transition duration-300 ease-in-out hover:scale-[1.05] hover:bg-[#FFF8F860] px-8 py-3 rounded-md"
                                    >
                                        Book a Demo
                                        <i className="fa fa-long-arrow-right ml-4"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div className="bg-[#000000] text-center text-white font-semibold font-['roboto'] text-[40px] py-16">
                    <div className="max-w-screen-2xl truncate px-2 mx-auto max-md:mx-2">
                        <h5 className="pb-20 max-md:pb-4 text-[40px] max-md:text-[36px] leading-[48px] font-[600]">
                            Brands we worked with
                        </h5>

                        <div className="">
                            <Carousel
                                containerClass="carousel-container"
                                responsive={data.responsive}
                                swipeable={false}
                                draggable={false}
                                showDots={false}
                                ssr={true}
                                infinite={true}
                                autoPlay={true}
                                autoPlaySpeed={1500}
                                keyBoardControl={true}
                                removeArrowOnDeviceType={[
                                    "tablet",
                                    "mobile",
                                    "desktop",
                                    "superLargeDesktop",
                                ]}
                                centerMode={true}
                            >
                                <div className="flex items-center justify-center h-full">
                                    <img
                                        className="object-contain mr-8 w-[80px] pt-5 max-lg:mr-2 max-md:py-4"
                                        src="./images/dashboardsectionone/tata.webp"
                                        alt=""
                                    />
                                </div>
                                <div className="flex items-center justify-center h-full">
                                    <img
                                        className="object-contain mx-8 w-[80px] max-lg:mx-2 max-md:py-4"
                                        src="./images/dashboardsectionone/audi.webp"
                                        alt=""
                                    />
                                </div>
                                <div className="flex items-center justify-center h-full">
                                    <img
                                        className="object-contain mx-8 w-[80px] pt-5 max-lg:mx-2 max-md:py-4"
                                        src="./images/dashboardsectionone/bajaj.webp"
                                        alt=""
                                    />
                                </div>
                                <div className="flex items-center justify-center h-full">
                                    <img
                                        className="object-contain mx-8 w-[80px] pt-5 max-lg:mx-2 max-md:py-4"
                                        src="./images/dashboardsectionone/droom.webp"
                                        alt=""
                                    />
                                </div>

                                <div className="flex items-center justify-center h-full">
                                    <img
                                        className="object-contain mx-8 w-[80px] pt-5 max-lg:mx-2 max-md:py-4"
                                        src="./images/dashboardsectionone/gromo.webp"
                                        alt=""
                                    />
                                </div>
                                <div className="flex items-center justify-center h-full">
                                    <img
                                        className="object-contain mx-8 w-[80px] pt-2 max-lg:mx-2 max-md:py-4"
                                        src="./images/dashboardsectionone/maruti.webp"
                                        alt=""
                                    />
                                </div>
                                <div className="flex items-center justify-center h-full">
                                    <img
                                        className="object-contain ml-8 w-[80px] px-2.5 max-lg:ml-2 max-md:py-4"
                                        src="./images/dashboardsectionone/olx.webp"
                                        alt=""
                                    />
                                </div>
                                <div className="flex items-center justify-center h-full">
                                    <img
                                        className="object-contain ml-8 w-[80px] pt-5 max-lg:ml-2 max-md:py-4"
                                        src="./images/dashboardsectionone/royal.webp"
                                        alt=""
                                    />
                                </div>
                                <div className="flex items-center justify-center h-full">
                                    <img
                                        className="object-contain ml-8 w-[80px] max-lg:ml-2 max-md:py-4"
                                        src="./images/dashboardsectionone/skoda.webp"
                                        alt=""
                                    />
                                </div>
                                <div className="flex items-center justify-center h-full">
                                    <img
                                        className="object-contain ml-8 w-[80px] pt-5 max-lg:ml-2 max-md:py-4"
                                        src="./images/dashboardsectionone/volks.webp"
                                        alt=""
                                    />
                                </div>
                            </Carousel>
                        </div>
                    </div>
                </div>
                <section
                    className="bg-no-repeat bg-cover"
                    style={{
                        backgroundImage: `url('./images/predictive/sectiontwo.webp')`,
                    }}
                >
                    <section className="max-w-screen-2xl mx-auto max-md:px-2 py-20 max-md:py-2">
                        <div>
                            <h3 className="mt-10 mb-5 text-5xl max-md:text-2xl text-white">
                                <span className="text-[#6091E2]">
                                    IoT-Integrated Platforms
                                </span>{" "}
                            </h3>
                            <p className="mb-20 max-md:mb-2 text-white mx-20 max-md:mx-2 max-md:text-xs text-[18px]">
                                Use ML to predict equipment failure in
                                manufacturing or energy sectors.
                            </p>
                        </div>
                        <div className="grid max-lg:grid-cols-2 grid-cols-3 max-sm:grid-cols-1">
                            <div className="div-cards">
                                <img
                                    src="../images/vrbank/logos/first.svg"
                                    alt=""
                                />
                                <h5>Real-Time Data Collection</h5>
                                <p>
                                    Continuously gathers data from connected
                                    devices and sensors, providing up-to-date
                                    insights into equipment performance.
                                </p>
                            </div>
                            <div className="div-cards">
                                <img
                                    src="../images/vrbank/logos/second.svg"
                                    alt=""
                                />
                                <h5>Machine Learning-Powered Predictions</h5>
                                <p>
                                    Uses machine learning algorithms to analyze
                                    patterns and predict potential equipment
                                    failures before they happen.
                                </p>
                            </div>
                            <div className="div-cards">
                                <img
                                    src="../images/vrbank/logos/third.svg"
                                    alt=""
                                />
                                <h5>Automated Alerts and Notifications</h5>
                                <p>
                                    Instantly notifies operators of any
                                    anomalies, allowing for quick intervention
                                    and minimizing downtime.
                                </p>
                            </div>
                        </div>
                    </section>
                </section>
                <section className="bg-white text-[#1C2347]">
                    <section className="max-w-screen-2xl mx-auto max-md:px-2  py-20 max-md:py-2 \">
                        <div>
                            <h3 className="mt-10 mb-5 text-5xl max-md:text-2xl text-white">
                                <span className="text-[#6091E2]">
                                    Asset Monitoring SaaS
                                </span>{" "}
                            </h3>
                            <p className="mb-20 max-md:mb-2 text-[#1C2347] mx-20 max-md:mx-2 max-md:text-xs text-[18px]">
                                Analyze sensor data to optimize equipment
                                lifecycle management.
                            </p>
                        </div>
                        <div className="grid max-lg:grid-cols-2 grid-cols-3 max-sm:grid-cols-1">
                            <div className="div-cards-predictive ">
                                <img
                                    src="../images/vrbank/logos/first.svg"
                                    alt=""
                                />
                                <h5>Real-Time Insights</h5>
                                <p>
                                    Get up-to-the-minute data on asset
                                    performance. Identify potential issues
                                    instantly.Act fast to maintain operational
                                    efficiency.
                                </p>
                            </div>
                            <div className="div-cards-predictive">
                                <img
                                    src="../images/vrbank/logos/second.svg"
                                    alt=""
                                />
                                <h5>Predictive Analytics</h5>
                                <p>
                                    Utilize AI-driven predictions to foresee
                                    failures.Plan proactive maintenance with
                                    confidence.Keep downtime to an absolute
                                    minimum.
                                </p>
                            </div>
                            <div className="div-cards-predictive">
                                <img
                                    src="../images/vrbank/logos/third.svg"
                                    alt=""
                                />
                                <h5>Centralized Control</h5>
                                <p>
                                    Streamline asset monitoring on a single
                                    platform.Access and manage data
                                    effortlessly.Gain complete control from one
                                    dashboard.
                                </p>
                            </div>
                        </div>
                    </section>
                </section>
                <LastSection />
            </div>
        </>
    );
}
