import Navigation from "../Navigation";
import { IHealthCareViewProps } from "./HealthCare.interfaces";
import BookDemo from "../BookDemo";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import data from "../ResponisveCarousel.json";
import UserLoginBox from "../UserLoginBox";
import LastSection from "../LastSection";
import MidSectionProductPage from "../MidSectionProduct";
export default function HealthCareView(props: IHealthCareViewProps) {
    return (
        <>
            {props.showlogin === true && (
                <UserLoginBox
                    redirectionURL="/metaverse/65b741def8309762720ad53f"
                    loginController={props.loginController}
                /> // Since the line 122, says Login to Eternity City, but can be changed later => TP | 2024-01-30 14:39:44
            )}
            {props.showDemo === true && (
                <BookDemo demoController={props.demoController} />
            )}
            <div className="font-['roboto']">
                <section className="relative h-auto">
                    <video
                        autoPlay
                        muted
                        loop
                        playsInline
                        className="absolute top-0 left-0 w-full h-full object-cover"
                    >
                        <source
                            src="./images/health/headerhealthcare.webm"
                            type="video/webm"
                        />
                        Your browser does not support the video tag.
                    </video>
                    <div className="absolute inset-0 bg-black opacity-50"></div>
                    <Navigation />
                    <div className="relative z-10 flex flex-col items-center justify-center h-full text-white">
                        <div className="text-left max-w-screen-2xl mx-auto max-md:px-2">
                            <div className="text-center py-72 max-md:py-48">
                                <div className="mx-20 max-md:m-2 max-md:text-center">
                                    <h3 className="my-10 max-md:mt-10 max-md:mb-5 text-5xl max-md:text-5xl text-white">
                                        <span className="text-white">
                                            Healthcare AI
                                        </span>{" "}
                                    </h3>
                                    <p className="mb-20 max-md:mb-2 text-white mx-20 max-md:mx-2 max-md:text-sm text-[18px]">
                                        Leverage AI to enhance diagnostics,
                                        treatment planning, and patient care.
                                        Improve accuracy, speed, and outcomes
                                        through intelligent analysis of medical
                                        data. Transform healthcare operations
                                        with innovative AI-powered solutions for
                                        better patient experiences
                                    </p>
                                    <button
                                        onClick={props.demoController}
                                        className="border-2 bg-[#FFF8F840] text-white max-md:mt-2 transition duration-300 ease-in-out hover:scale-[1.05] hover:bg-[#FFF8F860] px-8 py-3 rounded-md"
                                    >
                                        Book a Demo
                                        <i className="fa fa-long-arrow-right ml-4"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div className="bg-[#000000] text-center text-white font-semibold font-['roboto'] text-[40px] py-16">
                    <div className="max-w-screen-2xl truncate px-2 mx-auto max-md:mx-2">
                        <h5 className="pb-20 max-md:pb-4 text-[40px] max-md:text-[36px] leading-[48px] font-[600]">
                            Brands we worked with
                        </h5>

                        <div className="">
                            <Carousel
                                containerClass="carousel-container"
                                responsive={data.responsive}
                                swipeable={false}
                                draggable={false}
                                showDots={false}
                                ssr={true}
                                infinite={true}
                                autoPlay={true}
                                autoPlaySpeed={1500}
                                keyBoardControl={true}
                                removeArrowOnDeviceType={[
                                    "tablet",
                                    "mobile",
                                    "desktop",
                                    "superLargeDesktop",
                                ]}
                                centerMode={true}
                            >
                                <div className="flex items-center justify-center h-full">
                                    <img
                                        className="object-contain mr-8 w-[80px] pt-5 max-lg:mr-2 max-md:py-4"
                                        src="./images/dashboardsectionone/tata.webp"
                                        alt=""
                                    />
                                </div>
                                <div className="flex items-center justify-center h-full">
                                    <img
                                        className="object-contain mx-8 w-[80px] max-lg:mx-2 max-md:py-4"
                                        src="./images/dashboardsectionone/audi.webp"
                                        alt=""
                                    />
                                </div>
                                <div className="flex items-center justify-center h-full">
                                    <img
                                        className="object-contain mx-8 w-[80px] pt-5 max-lg:mx-2 max-md:py-4"
                                        src="./images/dashboardsectionone/bajaj.webp"
                                        alt=""
                                    />
                                </div>
                                <div className="flex items-center justify-center h-full">
                                    <img
                                        className="object-contain mx-8 w-[80px] pt-5 max-lg:mx-2 max-md:py-4"
                                        src="./images/dashboardsectionone/droom.webp"
                                        alt=""
                                    />
                                </div>

                                <div className="flex items-center justify-center h-full">
                                    <img
                                        className="object-contain mx-8 w-[80px] pt-5 max-lg:mx-2 max-md:py-4"
                                        src="./images/dashboardsectionone/gromo.webp"
                                        alt=""
                                    />
                                </div>
                                <div className="flex items-center justify-center h-full">
                                    <img
                                        className="object-contain mx-8 w-[80px] pt-2 max-lg:mx-2 max-md:py-4"
                                        src="./images/dashboardsectionone/maruti.webp"
                                        alt=""
                                    />
                                </div>
                                <div className="flex items-center justify-center h-full">
                                    <img
                                        className="object-contain ml-8 w-[80px] px-2.5 max-lg:ml-2 max-md:py-4"
                                        src="./images/dashboardsectionone/olx.webp"
                                        alt=""
                                    />
                                </div>
                                <div className="flex items-center justify-center h-full">
                                    <img
                                        className="object-contain ml-8 w-[80px] pt-5 max-lg:ml-2 max-md:py-4"
                                        src="./images/dashboardsectionone/royal.webp"
                                        alt=""
                                    />
                                </div>
                                <div className="flex items-center justify-center h-full">
                                    <img
                                        className="object-contain ml-8 w-[80px] max-lg:ml-2 max-md:py-4"
                                        src="./images/dashboardsectionone/skoda.webp"
                                        alt=""
                                    />
                                </div>
                                <div className="flex items-center justify-center h-full">
                                    <img
                                        className="object-contain ml-8 w-[80px] pt-5 max-lg:ml-2 max-md:py-4"
                                        src="./images/dashboardsectionone/volks.webp"
                                        alt=""
                                    />
                                </div>
                            </Carousel>
                        </div>
                    </div>
                </div>
                <MidSectionProductPage
                    imagePosition="left"
                    heading="Medical Imaging SaaS"
                    subheading="Detect diseases in X-rays, MRIs, or CT scans using AI."
                    features={features}
                    imageSrc={"../images/health/hone.webp"}
                />
                <MidSectionProductPage
                    imagePosition="right"
                    heading="Drug Discovery Platforms"
                    subheading="Accelerating Innovation in Drug Discovery with Advanced Computational Tools."
                    features={featuresTwo}
                    imageSrc={"../images/health/htwo.webp"}
                />
                <MidSectionProductPage
                    imagePosition="left"
                    heading="Health Monitoring"
                    subheading="Track, Analyze, and Optimize Your Health in Real-Time"
                    features={featuresThree}
                    imageSrc={"../images/health/hthree.webp"}
                />
                <LastSection />
            </div>
        </>
    );
}
const features = [
    {
        title: "AI-Powered Image Analysis",
        description:
            "Leverages advanced AI algorithms to detect abnormalities, assist in diagnoses, and improve imaging accuracy.",
    },
    {
        title: "Cloud-Based Accessibility",
        description:
            "Provides secure, on-demand access to medical images from any device, enabling seamless collaboration and remote consultations.",
    },
    {
        title: "Scalable Storage Solutions",
        description:
            "Offers scalable cloud storage for managing large volumes of imaging data while ensuring compliance with healthcare regulations.",
    },
];
const featuresTwo = [
    {
        title: "AI-Driven Research",
        description:
            "Leverage machine learning to analyze vast datasets, identify drug candidates, and accelerate discovery timelines.",
    },
    {
        title: "Predictive Modeling",
        description:
            "Use advanced algorithms to simulate drug interactions and predict efficacy and safety outcomes.",
    },
    {
        title: "Automated Screening",
        description:
            "Streamline high-throughput screening processes to identify promising compounds with greater precision.",
    },
];
const featuresThree = [
    {
        title: "Real-Time Data Tracking",
        description:
            "Continuously monitor vital signs like heart rate, blood pressure, and oxygen levels using smart devices and sensors.",
    },
    {
        title: "AI-Powered Insights",
        description:
            "Analyze health data to provide personalized recommendations and early detection of potential health issues.",
    },
    {
        title: "Remote Monitoring",
        description:
            "Enable healthcare providers to track patient health remotely, ensuring timely interventions and reduced hospital visits.",
    },
];
