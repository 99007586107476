import { useEffect, useState } from "react";
import { IMidSectionProductPageContainerProps } from "./MidSectionProductPage.interfaces";
import MidSectionProductPageView from "./MidSectionProductPage.view";
export default function MidSectionProductPageContainer(
    props: IMidSectionProductPageContainerProps
) {
    const [showDemo, setShowDemo] = useState(false);
    const demoController = () => setShowDemo(!showDemo);
    return (
        <>
            <MidSectionProductPageView
                imagePosition={props.imagePosition}
                heading={props.heading}
                subheading={props.subheading}
                features={props.features}
                imageSrc={props.imageSrc}
                demoController={demoController}
                showDemo={showDemo}
            />
        </>
    );
}
