import SuperAdminMetaverseUsersView from "./SuperAdminMetaverseUsers.view";
import { SuperAdminMetaverseUsersContainerProps } from "./SuperAdminMetaverseUsers.interface";
import { useEffect, useState } from "react";
import { MetaverseUserService } from "../../Services";

export default function SuperAdminMetaverseUsersContainer(
    props: SuperAdminMetaverseUsersContainerProps
) {
    const [users, setUsers] = useState([]);
    const itemsPerPage = 10;
    const [currentPage, setCurrentPage] = useState(1);
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const [messageText, setMessageText] = useState("");
    const [messageClass, setMessageClass] = useState("");
    const [showMessage, setShowMessage] = useState(false);
    const messageController = () => setShowMessage(!showMessage);
    const handleNextPage = () => {
        setCurrentPage((prevPage) => prevPage + 1);
    };

    const handlePrevPage = () => {
        setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
    };
    useEffect(() => {
        async function fetchMetaverseUsers() {
            const _users = await MetaverseUserService.fetchMany();
            setUsers(_users?.data?.users ?? []);
        }
        fetchMetaverseUsers();
    }, []);
    const deleteMetaverseUser = async (id: string) => {
        try {
            const data = await MetaverseUserService.deleteById(id);
            if (data.message === `User ${id} deleted`) {
                setShowMessage(true);
                setMessageClass("success");
                setMessageText("Request Sent successfully!!");
                setTimeout(function () {
                    setShowMessage(false);
                    window.location.reload();
                }, 1000);
            } else {
                setShowMessage(true);
                setMessageClass("error");
                setMessageText(
                    data.message === "Unauthorized"
                        ? "Unauthorized"
                        : data.errors.details[0].message
                );
                setTimeout(function () {
                    setShowMessage(false);
                }, 1000);
            }
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <div>
            <SuperAdminMetaverseUsersView
                users={users}
                startIndex={startIndex}
                endIndex={endIndex}
                handleNextPage={handleNextPage}
                handlePrevPage={handlePrevPage}
                currentPage={currentPage}
                messageController={messageController}
                messageText={messageText}
                messageClass={messageClass}
                showMessage={showMessage}
                deleteMetaverseUser={deleteMetaverseUser}
            />
        </div>
    );
}
