import Navigation from "../Navigation";
import { IOilRefieneryViewProps } from "./OilRefienery.interfaces";
import BookDemo from "../BookDemo";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import data from "../ResponisveCarousel.json";
import UserLoginBox from "../UserLoginBox";
import LastSection from "../LastSection";
import MidSectionProductPage from "../MidSectionProduct";
export default function OilRefieneryView(props: IOilRefieneryViewProps) {
    return (
        <>
            {props.showlogin === true && (
                <UserLoginBox
                    redirectionURL="/metaverse/65b741def8309762720ad53f"
                    loginController={props.loginController}
                /> // Since the line 122, says Login to Eternity City, but can be changed later => TP | 2024-01-30 14:39:44
            )}
            {props.showDemo === true && (
                <BookDemo demoController={props.demoController} />
            )}
            <div className="font-['roboto']">
                <section className="relative h-auto">
                    <video
                        autoPlay
                        muted
                        loop
                        playsInline
                        className="absolute top-0 left-0 w-full h-full object-cover"
                    >
                        <source
                            src="./images/oilrefinery/headeroilrefinery.webm"
                            type="video/webm"
                        />
                        Your browser does not support the video tag.
                    </video>
                    <div className="absolute inset-0 bg-black opacity-50"></div>
                    <Navigation />
                    <div className="relative z-10 flex flex-col items-center justify-center h-full text-white">
                        <div className="text-left max-w-screen-2xl mx-auto max-md:px-2">
                            <div className="text-center py-72 max-md:py-48">
                                <div className="mx-20 max-md:m-2 max-md:text-center">
                                    <h3 className="my-10 max-md:mt-10 max-md:mb-5 text-5xl max-md:text-5xl text-white">
                                        <span className="text-white">
                                            Oil Refinery Robots
                                        </span>{" "}
                                    </h3>
                                    <p className="mb-20 max-md:mb-2 text-white mx-20 max-md:mx-2 max-md:text-sm text-[18px]">
                                        Revolutionize refinery operations with
                                        robots for firefighting, leak detection,
                                        emergency response, and structural
                                        inspections. Ensure safety, efficiency,
                                        and precision, even in the harshest
                                        environments.
                                    </p>
                                    <button
                                        onClick={props.demoController}
                                        className="border-2 bg-[#FFF8F840] text-white max-md:mt-2 transition duration-300 ease-in-out hover:scale-[1.05] hover:bg-[#FFF8F860] px-8 py-3 rounded-md"
                                    >
                                        Book a Demo
                                        <i className="fa fa-long-arrow-right ml-4"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div className="bg-[#000000] text-center text-white font-semibold font-['roboto'] text-[40px] py-16">
                    <div className="max-w-screen-2xl truncate px-2 mx-auto max-md:mx-2">
                        <h5 className="pb-20 max-md:pb-4 text-[40px] max-md:text-[36px] leading-[48px] font-[600]">
                            Brands we worked with
                        </h5>

                        <div className="">
                            <Carousel
                                containerClass="carousel-container"
                                responsive={data.responsive}
                                swipeable={false}
                                draggable={false}
                                showDots={false}
                                ssr={true}
                                infinite={true}
                                autoPlay={true}
                                autoPlaySpeed={1500}
                                keyBoardControl={true}
                                removeArrowOnDeviceType={[
                                    "tablet",
                                    "mobile",
                                    "desktop",
                                    "superLargeDesktop",
                                ]}
                                centerMode={true}
                            >
                                <div className="flex items-center justify-center h-full">
                                    <img
                                        className="object-contain mr-8 w-[80px] pt-5 max-lg:mr-2 max-md:py-4"
                                        src="./images/dashboardsectionone/tata.webp"
                                        alt=""
                                    />
                                </div>
                                <div className="flex items-center justify-center h-full">
                                    <img
                                        className="object-contain mx-8 w-[80px] max-lg:mx-2 max-md:py-4"
                                        src="./images/dashboardsectionone/audi.webp"
                                        alt=""
                                    />
                                </div>
                                <div className="flex items-center justify-center h-full">
                                    <img
                                        className="object-contain mx-8 w-[80px] pt-5 max-lg:mx-2 max-md:py-4"
                                        src="./images/dashboardsectionone/bajaj.webp"
                                        alt=""
                                    />
                                </div>
                                <div className="flex items-center justify-center h-full">
                                    <img
                                        className="object-contain mx-8 w-[80px] pt-5 max-lg:mx-2 max-md:py-4"
                                        src="./images/dashboardsectionone/droom.webp"
                                        alt=""
                                    />
                                </div>

                                <div className="flex items-center justify-center h-full">
                                    <img
                                        className="object-contain mx-8 w-[80px] pt-5 max-lg:mx-2 max-md:py-4"
                                        src="./images/dashboardsectionone/gromo.webp"
                                        alt=""
                                    />
                                </div>
                                <div className="flex items-center justify-center h-full">
                                    <img
                                        className="object-contain mx-8 w-[80px] pt-2 max-lg:mx-2 max-md:py-4"
                                        src="./images/dashboardsectionone/maruti.webp"
                                        alt=""
                                    />
                                </div>
                                <div className="flex items-center justify-center h-full">
                                    <img
                                        className="object-contain ml-8 w-[80px] px-2.5 max-lg:ml-2 max-md:py-4"
                                        src="./images/dashboardsectionone/olx.webp"
                                        alt=""
                                    />
                                </div>
                                <div className="flex items-center justify-center h-full">
                                    <img
                                        className="object-contain ml-8 w-[80px] pt-5 max-lg:ml-2 max-md:py-4"
                                        src="./images/dashboardsectionone/royal.webp"
                                        alt=""
                                    />
                                </div>
                                <div className="flex items-center justify-center h-full">
                                    <img
                                        className="object-contain ml-8 w-[80px] max-lg:ml-2 max-md:py-4"
                                        src="./images/dashboardsectionone/skoda.webp"
                                        alt=""
                                    />
                                </div>
                                <div className="flex items-center justify-center h-full">
                                    <img
                                        className="object-contain ml-8 w-[80px] pt-5 max-lg:ml-2 max-md:py-4"
                                        src="./images/dashboardsectionone/volks.webp"
                                        alt=""
                                    />
                                </div>
                            </Carousel>
                        </div>
                    </div>
                </div>
                <MidSectionProductPage
                    imagePosition="left"
                    heading="Firefighting Robots"
                    subheading="Tackle refinery fires with advanced sensors and fire suppression systems."
                    features={features}
                    imageSrc={"../images/oilrefinery/sone.webp"}
                />
                <MidSectionProductPage
                    imagePosition="right"
                    heading="Leak Detection Robots"
                    subheading="Identify and address gas or oil leaks before they escalate."
                    features={featuresTwo}
                    imageSrc={"../images/oilrefinery/stwo.webp"}
                />
                <MidSectionProductPage
                    imagePosition="left"
                    heading="Emergency Response Robots"
                    subheading="Assist in evacuation or containment in the event of chemical spills."
                    features={featuresThree}
                    imageSrc={"../images/oilrefinery/sthree.webp"}
                />
                <MidSectionProductPage
                    imagePosition="right"
                    heading="Robotic Arms with Sensors"
                    subheading="Detect corrosion, cracks, or leaks using ultrasonic, infrared, or laser sensors."
                    features={featuresFour}
                    imageSrc={"../images/oilrefinery/sfour.webp"}
                />
                <MidSectionProductPage
                    imagePosition="left"
                    heading="Autonomous Underwater Vehicles (AUVs)"
                    subheading="Inspect submerged pipelines or storage tanks."
                    features={featuresFive}
                    imageSrc={"../images/oilrefinery/sfive.webp"}
                />

                <LastSection />
            </div>
        </>
    );
}
const features = [
    {
        title: "Advanced Fire Suppression Systems",
        description:
            "Equipped with water cannons, foam dispensers, and chemical extinguishers to tackle fires effectively.",
    },
    {
        title: "Thermal and Smoke Sensors",
        description:
            "Utilizes cutting-edge sensors to detect heat, flames, and smoke for quick response.",
    },
    {
        title: "Remote Operation",
        description:
            "Controlled remotely, allowing safe operation in hazardous environments without risking human lives.",
    },
];
const featuresTwo = [
    {
        title: "High-Sensitivity Sensors",
        description:
            "Equipped with ultrasonic, infrared, and gas sensors to detect even the smallest leaks in pipelines or storage tanks.",
    },
    {
        title: "Real-Time Monitoring",
        description:
            "Continuously monitors for leaks and provides instant alerts to prevent potential hazards.",
    },
    {
        title: "Automated Leak Localization",
        description:
            "Pinpoints the exact location of leaks, reducing response time and minimizing operational downtime.",
    },
];
const featuresThree = [
    {
        title: "Disaster Assessment",
        description:
            "Equipped with sensors and cameras to assess hazardous situations, providing real-time data for effective decision-making.",
    },
    {
        title: "Search and Rescue",
        description:
            "Navigate through debris, confined spaces, or dangerous environments to locate and assist victims during emergencies.",
    },
    {
        title: "Hazard Mitigation",
        description:
            "Deploy fire suppression tools, chemical neutralizers, or other equipment to contain threats like fires or chemical spills.",
    },
];
const featuresFour = [
    {
        title: "Advanced Sensor Integration",
        description:
            "Equipped with ultrasonic, infrared, and laser sensors to detect corrosion, cracks, and structural weaknesses in real time.",
    },
    {
        title: "Precision Inspection",
        description:
            "Performs highly accurate evaluations of pipelines, storage tanks, and critical equipment with minimal human intervention.",
    },
    {
        title: "Remote Operability",
        description:
            "Controlled remotely to ensure safe inspections in hazardous or hard-to-reach areas, reducing risks for workers.",
    },
];
const featuresFive = [
    {
        title: "Submerged Pipeline Inspection",
        description:
            "Designed to efficiently inspect underwater pipelines, storage tanks, and subsea structures for damage or leaks.",
    },
    {
        title: "Advanced Navigation Systems",
        description:
            "Equipped with GPS, sonar, and AI-powered pathfinding to operate autonomously in complex underwater environments.",
    },
    {
        title: "Real-Time Data Transmission",
        description:
            "Provides live data on structural integrity, corrosion, or leaks to support timely maintenance decisions.",
    },
];
