import axios from "axios";
import SessionService from "./Session.service";
import SuperAdminService from "./SuperAdmin.service";
import DashboardUserService from "./DashboardUser.service";
import MetaverseUserService from "./MetaverseUser.service";

const accessOfPathMap: Record<string, string[]> = {
    "GET metaverse/[0-9a-fA-F]{24}/dashboard-users": ["super-admin"],
    "GET metaverse/[0-9a-fA-F]{24}/metaverse-users": ["super-admin"],
    "GET dashboard-user/metaverse/[0-9a-fA-F]{24}/metaverse-users": [
        "dashboard-user",
    ],
    "GET dashboard-users": ["super-admin"],
    "DELETE metaverse/[0-9a-fA-F]{24}": ["super-admin"],
    "DELETE dashboard-user/[0-9a-fA-F]{24}": ["super-admin"],
    "PUT dashboard-user": ["super-admin"],
    "GET users": ["super-admin"],
    "GET user/[0-9a-fA-F]{24}/metaverses": ["super-admin"],
    "PUT metaverse/[0-9a-fA-F]{24}/metaverse-user": ["metaverse-user"],
    "GET dashboard-user/[0-9a-fA-F]{24}/metaverses": ["super-admin"],
    "GET dashboard-user/[0-9a-fA-F]{24}": ["super-admin"],
    "GET dashboard-user/": ["dashboard-user"],
    "PUT metaverse/[0-9a-fA-F]{24}/dashboard-user/[0-9a-fA-F]{24}": [
        "super-admin",
    ],
    "PATCH dashboard-user/[0-9a-fA-F]{24}": ["super-admin"],
    "GET dashboard-user/metaverses": ["dashboard-user"],
    "POST user/code": ["metaverse-user"],
    "GET super-admin": ["super-admin"],
    "DELETE user": ["super-admin"],
};

function getAccessTokenForPath(method: string, path: string) {
    const route = `${method.toUpperCase()} ${path}`;
    const matchedRegex = Object.keys(accessOfPathMap).filter((regex) =>
        route.match(regex)
    )[0];
    // If matched and access token is not set, redirect to login => TP | 2024-02-09 13:29:08
    if (!matchedRegex) {
        return null;
    }
    const access = accessOfPathMap[matchedRegex];
    const loggedInUserType = SessionService.get("user-type");
    if (access.includes(loggedInUserType as string)) {
        return SessionService.get("accessToken");
    } else {
        return null;
    }
}

axios.interceptors.request.use((config: any) => {
    const innerConfig = config;
    const path = new URL(innerConfig.url).pathname
        .split("/")
        .slice(2)
        .join("/");
    const method = innerConfig.method;
    const accessToken = getAccessTokenForPath(method, path);
    if (accessToken) {
        innerConfig.headers.Authorization = `Bearer ${accessToken}`;
    }
    return innerConfig;
});

async function refreshCurrentUser() {
    let data;
    try {
        switch (SessionService.get("user-type")) {
            case "super-admin":
                data = await SuperAdminService.refreshToken();
                SessionService.set("accessToken", data.data.accessToken);
                SessionService.set("refreshToken", data.data.refreshToken);
                SessionService.set("user-type", "super-admin");
                SessionService.set(
                    "user",
                    JSON.stringify(data.data.superAdmin)
                );
                break;
            case "dashboard-user":
                data = await DashboardUserService.refreshToken();
                SessionService.set("accessToken", data.data.accessToken);
                SessionService.set("refreshToken", data.data.refreshToken);
                SessionService.set("user-type", "dashboard-user");
                SessionService.set(
                    "user",
                    JSON.stringify(data.data.dashboardUser)
                );
                break;
            case "metaverse-user":
                data = await MetaverseUserService.refreshToken();
                SessionService.set("accessToken", data.data.accessToken);
                SessionService.set("refreshToken", data.data.refreshToken);
                SessionService.set("user-type", "metaverse-user");
                SessionService.set("user", JSON.stringify(data.data.user));
                break;
            default:
                SessionService.clear();
                break;
        }
    } catch (error) {
        console.error(error);
        SessionService.clear();
        window.location.href = "/";
    }
}

let lastRetriedURL: string | null = null;
axios.interceptors.response.use(
    (response) => response,
    async (err) => {
        if (err.response && err.response.status === 401) {
            await refreshCurrentUser();
            console.log(err.config.url, lastRetriedURL);
            if (lastRetriedURL === err.config.url) {
                return;
            } else {
                lastRetriedURL = err.config.url;

                return axios(err.config);
            }
        } //else console.log(err.config.url, lastRetriedURL); // Remove this later => TP | 2024-02-06 15:10:59
    }
);

const ApiService = {
    get: async (url: string) => {
        try {
            const { data } = await axios({ method: "GET", url });
            return data;
        } catch (error: any) {
            console.error(error);
            return null;
        }
    },
    post: async (url: string, body: any, headers?: any) => {
        try {
            const { data } = await axios({
                method: "POST",
                url: url,
                data: body,
                headers: headers,
            });
            return data;
        } catch (error: any) {
            console.error(error);
            return null;
        }
    },
    put: async (url: string, body: any) => {
        try {
            const { data } = await axios({
                method: "PUT",
                url: url,
                data: body,
            });
            return data;
        } catch (error: any) {
            console.error(error);
            return null;
        }
    },
    patch: async (url: string, body: any) => {
        try {
            const { data } = await axios({
                method: "PATCH",
                url: url,
                data: body,
            });
            return data;
        } catch (error: any) {
            console.error(error);
            return null;
        }
    },
    delete: async (url: string) => {
        try {
            const { data } = await axios({ method: "DELETE", url });
            return data;
        } catch (error: any) {
            console.error(error);
            return null;
        }
    },
};

export default ApiService;
