import ApiService from "./API.service";
import axios from "axios";

const baseUrl = process.env.REACT_APP_METAVERSE_USER_MICROSERVICE_BASE_URL;
const baseUrlEmptra = process.env.REACT_APP_BASEURL_FOR_CONTACT;

const MetaverseUserService = {
    /**
     * POST /user
     * @access public
     */
    login: async (provider: string, accessToken: string) => {
        try {
            const url = `${baseUrl}user`; // Replace url to `metaverse-user`.
            const { data } = await ApiService.post(url, {
                token: accessToken,
                provider: provider,
            });
            return data;
        } catch (error: any) {
            console.error(error);
            return error.response ? error.response.data : {};
        }
    },
    /**
     * POST /user/code
     * @access user
     */
    loginWithCode: async (code: string) => {
        try {
            const url = `${baseUrl}user/code`;
            const data = await ApiService.post(url, {
                code,
            });
            return data;
        } catch (error: any) {
            console.error(error);
            return error.response ? error.response.data : {};
        }
    },
    /**
     * GET /user
     * @access user
     */
    fetch: async () => {
        try {
            const url = `${baseUrl}user`; // Replace url to `metaverse-user`.
            const data = await ApiService.get(url);
            return data;
        } catch (error: any) {
            console.error(error);
            return error.response ? error.response.data : {};
        }
    },
    /**
     * PATCH /user
     * @access user
     */
    update: async (name: string, phoneNumber: string, email: string) => {
        try {
            const url = `${baseUrl}user`; // Replace url to `metaverse-user`.
            const data = await ApiService.patch(url, {
                name,
                phoneNumber,
                email,
            });
            return data;
        } catch (error: any) {
            console.error(error);
            return error.response ? error.response.data : {};
        }
    },
    /**
     * DELETE /user
     * @access user
     */
    delete: async () => {
        try {
            const url = `${baseUrl}user`; // Replace url to `metaverse-user`.
            const data = await ApiService.delete(url);
            return data;
        } catch (error: any) {
            console.error(error);
            return error.response ? error.response.data : {};
        }
    },
    /**
     * DELETE /user/{id}
     * @access super-admin
     */
    deleteById: async (id: string) => {
        try {
            const url = `${baseUrl}user/${id}`; // Replace url to `metaverse-user`.
            const data = await ApiService.delete(url);
            return data;
        } catch (error: any) {
            console.error(error);
            return error.response ? error.response.data : {};
        }
    },
    /**
     * PATCH /user/refresh-token
     * @access public // Will be changed to dashboard-user later => TP
     */
    refreshToken: async () => {
        try {
            const url = `${baseUrl}user/refresh-token`; // Replace url to `metaverse-user`.
            const data = await ApiService.patch(url, {});
            return data;
        } catch (error: any) {
            console.error(error);
            return error.response ? error.response.data : {};
        }
    },
    /**
     * POST /user/book-demo
     */
    bookDemo: async (
        businessEmail: string,
        companyName: string,
        message: string,
        name: string,
        phoneNumber: string
    ) => {
        try {
            const url = `${baseUrlEmptra}/meta/book-demo`; // Replace url to `metaverse-user`.
            const token = process.env.REACT_APP_EMPTRA_TOKEN;

            const headers = {
                token: token,
                "Content-Type": "application/json",
                versionNumber: "v2",
            };
            const { data } = await axios.post(
                url,
                {
                    businessEmail,
                    companyName,
                    message,
                    phoneNumber,
                    name,
                },
                { headers }
            );
            return data;
        } catch (error: any) {
            console.error(error);
            return error?.response ? error?.response?.data : {};
        }
    },
    /**
     * GET /user/{id}
     * @access super-admin
     */
    fetchById: async (id: string) => {
        try {
            const url = `${baseUrl}user/${id}`; // Replace url to `metaverse-user`.
            const data = await ApiService.get(url);
            return data;
        } catch (error: any) {
            console.error(error);
            return error.response ? error.response.data : {};
        }
    },
    /**
     * GET /user/metaverses
     * @access user
     */
    fetchMetaverses: async () => {
        try {
            const url = `${baseUrl}user/metaverses`; // Replace url to `metaverse-user`.
            const data = await ApiService.get(url);
            return data;
        } catch (error: any) {
            console.error(error);
            return error.response ? error.response.data : {};
        }
    },
    /**
     * GET user/{id}/metaverses
     * @access super-admin
     */
    fetchMetaversesByUserID: async (id: string) => {
        try {
            const url = `${baseUrl}user/${id}/metaverses`; // Replace url to `metaverse-user`.
            const data = await ApiService.get(url);
            return data;
        } catch (error: any) {
            console.error(error);
            return error.response ? error.response.data : {};
        }
    },
    /**
     * GET /user/events
     * @access user
     */
    fetchUserEvents: async () => {
        try {
            const url = `${baseUrl}user/events`; // Replace url to `metaverse-user`.
            const data = await ApiService.get(url);
            return data;
        } catch (error: any) {
            console.error(error);
            return error.response ? error.response.data : {};
        }
    },
    /**
     * GET /user/events/{id}
     * @access super-admin
     */
    fetchUserEventsByUserId: async (id: string) => {
        try {
            const url = `${baseUrl}user/events/${id}`; // Replace url to `metaverse-user`.
            const data = await ApiService.get(url);
            return data;
        } catch (error: any) {
            console.error(error);
            return error.response ? error.response.data : {};
        }
    },
    /**
     * GET /user/devices
     * @access user
     */
    fetchUserDevices: async () => {
        try {
            const url = `${baseUrl}user/devices`; // Replace url to `metaverse-user`.
            const data = await ApiService.get(url);
            return data.devices;
        } catch (error: any) {
            console.error(error);
            return error.response ? error.response.data : {};
        }
    },
    /**
     * GET /user/devices/{id}
     * @access super-admin
     */
    fetchUserDevicesByUserId: async (id: string) => {
        try {
            const url = `${baseUrl}user/devices/${id}`; // Replace url to `metaverse-user`.
            const data = await ApiService.get(url);
            return data.devices;
        } catch (error: any) {
            console.error(error);
            return error.response ? error.response.data : {};
        }
    },
    fetchMany: async () => {
        try {
            const url = `${baseUrl}users`;
            const data = await ApiService.get(url);
            return data;
        } catch (error: any) {
            console.error(error);
            return error.response ? error.response.data : {};
        }
    },
};

export default MetaverseUserService;
