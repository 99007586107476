import { Link } from "react-router-dom";
import { SuperAdminDashboardUserViewProps } from "./SuperAdminDashboardUser.interfaces";
import Message from "../Message";

export default function SuperAdminDashboardUserView(
    props: SuperAdminDashboardUserViewProps
) {
    return (
        <>
            {props.showMessage && (
                <Message
                    messageText={props.messageText}
                    messageClass={props.messageClass}
                    demoController={props.messageController}
                />
            )}
            <div className="text-left p-4 font-['roboto'] ">
                <div className="text-left">
                    <Link to="/super-admin/dashboard-users">
                        <span className="font-['roboto'] text-sm font-semibold text-[#489BF6]">
                            {"<< Back"}
                        </span>
                    </Link>
                </div>
                <h1 className="py-2">Dashboard User</h1>
                <div className="border-2 p-4 my-1">
                    <div className="superadmin-dashboard-user">
                        <span className="">Name:</span>{" "}
                        {props.dashboardUser.name}
                    </div>
                    <div className="superadmin-dashboard-user">
                        <span className="">Email:</span>{" "}
                        {props.dashboardUser.email}
                    </div>
                    <div className="superadmin-dashboard-user">
                        <span className="">Phone No:</span>{" "}
                        {props.dashboardUser.phoneNumber}
                    </div>
                    <div className="superadmin-dashboard-user">
                        <span className="">Created At:</span>{" "}
                        {new Date(
                            props.dashboardUser.createdAt
                        ).toLocaleString()}
                    </div>
                    <div className="superadmin-dashboard-user">
                        <span className="">Updated At:</span>{" "}
                        {new Date(
                            props.dashboardUser.updatedAt
                        ).toLocaleString()}
                    </div>
                </div>
                <div className="border-2 p-4 my-1">
                    <h2>Metaverse</h2>
                    {props.dashboardUser?.metaverses &&
                        props.dashboardUser.metaverses.map(
                            (metaverse: any, index: number) => (
                                <div
                                    key={index}
                                    className="border-2 p-2 my-2 hover:bg-[#489BF6] hover:text-white rounded-md"
                                >
                                    <Link
                                        to={`/super-admin/metaverse/${metaverse.metaverseID._id}`}
                                        className="flex justify-between items-center w-full p-2 rounded-md"
                                    >
                                        <div>
                                            <div className="superadmin-dashboard-user py-1">
                                                <span>Name: </span>
                                                {metaverse.metaverseID.name}
                                            </div>
                                            <div className="superadmin-dashboard-user py-1">
                                                <span>Description:</span>{" "}
                                                {
                                                    metaverse.metaverseID
                                                        .description
                                                }
                                            </div>
                                        </div>
                                        <div className="bg-green-600 p-3 rounded-md">
                                            {metaverse.metaverseID.admin ===
                                            props.dashboardUser._id
                                                ? "Admin"
                                                : null}
                                        </div>
                                    </Link>
                                </div>
                            )
                        )}
                </div>
                <div className="border-2 p-4 my-1">
                    <h2 className="py-2 text-black">
                        Add Dashboard User To selected Metaverse
                    </h2>
                    <select
                        name="metaverse"
                        onChange={props.selectMetaverse}
                        className="px-10 py-3 block my-2 w-full rounded-md border-2"
                    >
                        {props.metaverses.map(
                            (metaverse: any, index: number) => (
                                // Since the api request is paginated, we might need to add an event listener that can fetch more data when the user scrolls to the bottom of the list. => TP | 2024-02-06 12:05:58
                                <option key={index} value={metaverse._id}>
                                    {metaverse.name}
                                </option>
                            )
                        )}
                    </select>
                    <button
                        className="border-2 bg-[#489BF6] text-white font-[600] text-[14px] rounded-lg py-3 w-full"
                        onClick={props.addDashboardUserToMetaverse}
                    >
                        Add User To Metaverse
                    </button>
                </div>
            </div>
        </>
    );
}
