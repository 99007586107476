import { IMidSectionProductPageViewProps } from "./MidSectionProductPage.interfaces";
import BookDemo from "../BookDemo";
export default function MidSectionProductPageView(
    props: IMidSectionProductPageViewProps
) {
    return (
        <>
            {props.showDemo === true && (
                <BookDemo demoController={props.demoController} />
            )}
            <div className="bg-white text-center text-white text-left max-w-screen-2xl mx-auto max-md:px-2 font-semibold font-['roboto']  py-16 max-md:py-5 grid grid-cols-2 gap-[20px] items-center max-md:gap-[10px] max-md:grid-cols-1">
                {props.imagePosition === "left" && (
                    <img
                        alt=""
                        src={props.imageSrc}
                        className="rounded-xl max-h-[500px] max-md:rounded-none max-md:h-auto"
                    />
                )}
                <div className="text-left">
                    <div className="text-[#1C2347] p-2">
                        <div>
                            <h5 className="text-[40px] pb-4 text-[#6091E2] leading-[58px] max-md:text-[36px]">
                                {props.heading}
                            </h5>
                            <p className="text-[#6B6B6B] font-[400] text-[18px] pb-7">
                                {props.subheading}
                            </p>
                        </div>
                        <div>
                            {props.features.map((feature, index) => (
                                <div key={index}>
                                    <h5 className="text-[24px] pb-2">
                                        {feature.title}
                                    </h5>
                                    <p className="text-[#6B6B6B] font-[400] text-[16px] pb-4">
                                        {feature.description}
                                    </p>
                                </div>
                            ))}
                            <button
                                className="bg-blue-100 px-5 max-md:px-[78px] py-3 text-white  transition duration-300 ease-in-out  hover:scale-[1.05] hover:bg-gradient-to-l rounded-md mr-4 bg-gradient-to-r from-blue-500 to-purple-600"
                                onClick={props.demoController}
                            >
                                Ask an Expert
                            </button>
                        </div>
                    </div>
                </div>
                {props.imagePosition === "right" && (
                    <img
                        alt=""
                        src={props.imageSrc}
                        className="rounded-xl max-h-[500px] max-md:rounded-none max-md:h-auto"
                    />
                )}
            </div>
        </>
    );
}
