import BookDemo from "../BookDemo";
import InfoCard from "./Components";
import { INavigationViewProps } from "./Navigation.interfaces";
import { Link } from "react-router-dom";
export default function NavigationView(props: INavigationViewProps) {
    return (
        <>
            {props.showDemo === true && (
                <BookDemo demoController={props.demoController} />
            )}
            {props.showMenu === true && (
                <div className="font-['roboto'] h-screen w-screen hidden max-md:block fixed z-[9999]">
                    <div
                        className="bg-[rgba(0,0,0,.8)] h-screen w-screen z-[99999] absolute"
                        onClick={props.menuController}
                    >
                        <div className="flex text-white justify-end">
                            <div
                                className="w-[30px] bg-[#6B6B6B7a] my-10 mx-2 max-[330px]:mx-1 rounded-full flex justify-center cursor-pointer"
                                onClick={props.menuController}
                            >
                                <i className="fa fa-close text-xl cursor-pointer"></i>
                            </div>
                        </div>
                    </div>
                    {/* Hello adding a comment explicitily */}
                    <div className="absolute bg-gradient-to-b from-blue-500 to-purple-600 h-screen z-[999999] py-2 px-2 w-[280px] overflow-auto">
                        <div className="bg-white rounded-xl mx-2 my-[1rem] flex justify-center">
                            <Link onClick={() => props.scrollToTop} to="/">
                                <img
                                    src="../images/darklogo.webp"
                                    className="max-w-[200px] py-[1rem] cursor-pointer"
                                    alt=""
                                />
                            </Link>
                        </div>
                        <div className="h-full mt-10 text-left text-white font-roboto font-semibold">
                            <ul>
                                <li className="py-4 border-b-[1px] border-b-[#3798b5cc]">
                                    <Link
                                        onClick={() => props.scrollToTop}
                                        to="/"
                                    >
                                        <h5 className="cursor-pointer px-2 font-[400] cursor-pointer">
                                            <i className="fa fa-home text-[16px] pr-2"></i>{" "}
                                            Home
                                        </h5>
                                    </Link>
                                </li>

                                <li className="py-4 border-b-[1px] border-b-[#3798b5cc] group">
                                    <div
                                        className="flex items-center justify-between cursor-pointer"
                                        onClick={props.dropdownController}
                                    >
                                        <h5 className="px-2 font-[400]">
                                            <i className="fa fa-gears text-[16px] pr-2"></i>{" "}
                                            VR Solutions
                                        </h5>
                                        {!props.showDropdown && (
                                            <i className="fa fa-angle-down pr-2"></i>
                                        )}
                                        {props.showDropdown && (
                                            <i className="fa fa-angle-up pr-2"></i>
                                        )}
                                    </div>
                                    {props.showDropdown && (
                                        <ul className="p-4">
                                            <Link
                                                onClick={() =>
                                                    props.scrollToTop
                                                }
                                                to="/vrworld"
                                            >
                                                <li className="py-3 cursor-pointer border-b-[1px] border-b-[#3798b5cc] mx-2 hover:bg-[#0B0B0BAB] hover:rounded-xl px-2 font-[400]">
                                                    <i className="fa fa-globe pr-2 text-[14px]"></i>{" "}
                                                    Vr World
                                                </li>
                                            </Link>
                                            <Link
                                                onClick={() =>
                                                    props.scrollToTop
                                                }
                                                to="/vrbank"
                                            >
                                                <li className="py-3 cursor-pointer border-b-[1px] border-b-[#3798b5cc] mx-2 hover:bg-[#0B0B0BAB] hover:rounded-xl px-2 font-[400]">
                                                    <i className="fa fa-bank pr-2 text-[14px]"></i>
                                                    Vr Bank
                                                </li>
                                            </Link>
                                            <Link
                                                onClick={() =>
                                                    props.scrollToTop
                                                }
                                                to="/vrauto"
                                            >
                                                <li className="py-3 cursor-pointer border-b-[1px] border-b-[#3798b5cc] mx-2 hover:bg-[#0B0B0BAB] hover:rounded-xl px-2 font-[400]">
                                                    <i className="fa fa-automobile text-[14px] pr-2"></i>{" "}
                                                    Vr Auto
                                                </li>
                                            </Link>
                                            <Link
                                                onClick={() =>
                                                    props.scrollToTop
                                                }
                                                to="/vrretail"
                                            >
                                                <li className="py-3 cursor-pointer mx-2 hover:bg-[#0B0B0BAB] hover:rounded-xl px-2 font-[400]">
                                                    <i className="fa fa-shopping-bag pr-2 text-[14px]"></i>
                                                    Vr Retail
                                                </li>
                                            </Link>
                                        </ul>
                                    )}
                                </li>
                                <li className="py-4 border-b-[1px] border-b-[#3798b5cc]">
                                    <Link
                                        onClick={() => props.scrollToTop}
                                        to="/oilrefienery"
                                    >
                                        <h5 className="px-2 font-[400] cursor-pointer">
                                            <i className="fa fa-cubes text-[14px] pr-2"></i>{" "}
                                            Oil Refienery Robots
                                        </h5>
                                    </Link>
                                </li>
                                <li className="py-4 border-b-[1px] border-b-[#3798b5cc]">
                                    <Link
                                        onClick={() => props.scrollToTop}
                                        to="/healthcare"
                                    >
                                        <h5 className="px-2 font-[400] cursor-pointer">
                                            <i className="fa fa-heartbeat text-[14px] pr-2"></i>{" "}
                                            Healthcare AI
                                        </h5>
                                    </Link>
                                </li>
                                <li className="py-4 border-b-[1px] border-b-[#3798b5cc]">
                                    <Link
                                        onClick={() => props.scrollToTop}
                                        to="/predective"
                                    >
                                        <h5 className="px-2 font-[400] cursor-pointer">
                                            <i className="fa fa-code-fork text-[14px] pr-2"></i>{" "}
                                            Predictive Solutions
                                        </h5>
                                    </Link>
                                </li>
                                <li className="py-4 border-b-[1px] border-b-[#3798b5cc]">
                                    <Link
                                        onClick={() => props.scrollToTop}
                                        to="/industries"
                                    >
                                        <h5 className="px-2 font-[400] cursor-pointer">
                                            <i className="fa fa-industry text-[14px] pr-2"></i>{" "}
                                            Industries
                                        </h5>
                                    </Link>
                                </li>
                                <li className="py-4 border-b-[1px] border-b-[#3798b5cc]">
                                    <Link
                                        onClick={() => props.scrollToTop}
                                        to="/aboutus"
                                    >
                                        <h5 className="px-2 font-[400] cursor-pointer">
                                            <i className="fa fa-group text-[14px] pr-2"></i>
                                            About Us
                                        </h5>
                                    </Link>
                                </li>
                                <li className="py-4 border-b-[1px] border-b-[#3798b5cc]">
                                    <Link
                                        onClick={() => props.scrollToTop}
                                        to="/contactus"
                                    >
                                        <h5 className="px-2 font-[400] cursor-pointer">
                                            <i className="fa fa-phone text-[16px] pr-2"></i>{" "}
                                            Contact Us
                                        </h5>
                                    </Link>
                                </li>
                                <li className="py-4">
                                    <Link
                                        onClick={() => props.scrollToTop}
                                        to="/faq"
                                    >
                                        <h5 className="px-2 font-[400] cursor-pointer">
                                            {" "}
                                            <i className="fa fa-question-circle-o text-[16px] pr-2"></i>{" "}
                                            FAQ
                                        </h5>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            )}
            <div
                className={`fixed w-full z-[999] ${
                    props.showProducts
                        ? "bg-black"
                        : props.scroll
                          ? "bg-black"
                          : ""
                }`}
                id="navbar"
            >
                <div
                    className={`font-['roboto'] flex max-w-screen-2xl px-2 mx-auto max-md:mx-2 pt-10 pb-10 justify-between font-[600] max-lg:text-[14px] items-center text-white`}
                >
                    <div>
                        <Link onClick={() => props.scrollToTop} to="/">
                            <img
                                src="../images/whitelogo.webp"
                                className="max-w-[250px]"
                                alt=""
                            />
                        </Link>
                    </div>
                    <div className="flex max-md:hidden">
                        <div
                            className="px-4 max-lg:px-2 flex items-center cursor-pointer"
                            onClick={() => props.productController()}
                        >
                            <p
                                className={`pr-2 ${
                                    props.showProducts
                                        ? "border-b-[1px] border-b-white"
                                        : ``
                                }`}
                            >
                                Solutions
                            </p>
                            {!props.showProducts && (
                                <i className="fa fa-angle-down"></i>
                            )}
                            {props.showProducts && (
                                <i className="fa fa-angle-up -mt-1"></i>
                            )}
                        </div>
                        <Link onClick={() => props.scrollToTop} to="/aboutus">
                            <p className="px-4 max-lg:px-2 cursor-pointer">
                                About us
                            </p>
                        </Link>
                        <Link onClick={() => props.scrollToTop} to="/contactus">
                            <p className="px-4 max-lg:px-2 cursor-pointer">
                                Contact us
                            </p>
                        </Link>
                        <Link onClick={() => props.scrollToTop} to="/faq">
                            <p className="px-4 max-lg:px-2 cursor-pointer">
                                FAQs
                            </p>
                        </Link>
                        <Link
                            onClick={() => props.scrollToTop}
                            to="/industries"
                        >
                            <p className="px-4 max-lg:px-2 cursor-pointer">
                                Industries
                            </p>
                        </Link>
                    </div>
                    <div className="flex items-center max-md:hidden cursor-pointer">
                        <button
                            className="bg-white rounded text-black px-8 max-lg:px-4 py-3 transition duration-300 ease-in-out hover:scale-[1.05] rounded-md"
                            onClick={props.demoController}
                        >
                            Ask an Expert
                        </button>
                    </div>
                    <div
                        className="hidden max-md:block cursor-pointer"
                        onClick={props.menuController}
                    >
                        <i className="fa fa-bars"></i>
                    </div>
                </div>
            </div>
            {props.showProducts === true && (
                <div
                    className="h-screen fixed w-screen z-[999999]"
                    onClick={props.productController}
                >
                    <div className="w-full max-md:hidden text-white text-left font-['roboto'] justify-between fixed mt-28 z-20">
                        <div className="bg-black border-t-[1px] border-t-[#3C3C3C] left-0 right-0 px-2">
                            <div className="grid grid-cols-4 max-w-screen-2xl px-4 max-lg:px-0 mx-auto max-md:mx-2 py-4">
                                <div className="py-3 max-[1000px]:px-1 border-r-[1px] border-r-[#4E4E4E] ">
                                    <div className="px-4 max-lg:px-1">
                                        <div className="py-1">
                                            <div className="px-1">
                                                <div className="px-3">
                                                    <img
                                                        src="../images/navigation/oilrefinery.webp"
                                                        alt=""
                                                        className="py-3"
                                                    />
                                                    <h5
                                                        className="font-[600] py-2 max-lg:pb-1 text-transparent text-[20px] max-lg:text-[18px] leading-[25px]"
                                                        style={{
                                                            background:
                                                                "linear-gradient(90deg, #489DF7 0%, #7637E2 100%)",
                                                            WebkitBackgroundClip:
                                                                "text",
                                                            WebkitTextFillColor:
                                                                "transparent",
                                                        }}
                                                    >
                                                        Oil Refinery Robots
                                                    </h5>
                                                </div>
                                                <Link to="/oilrefienery">
                                                    <InfoCard
                                                        title="Firefighting Robots"
                                                        description="Tackle refinery fires with advanced sensors and fire suppression systems."
                                                    />
                                                </Link>
                                                <Link to="/oilrefienery">
                                                    <InfoCard
                                                        title="Leak Detection Robots"
                                                        description="Identify and address gas or oil leaks before they escalate."
                                                    />
                                                </Link>
                                                <Link to="/oilrefienery">
                                                    <InfoCard
                                                        title="Emergency Response Robots"
                                                        description="Assist in evacuation or containment in the event of chemical spills."
                                                    />
                                                </Link>
                                                <Link to="/oilrefienery">
                                                    <InfoCard
                                                        title="Robotic Arms with Sensors"
                                                        description="Detect corrosion, cracks, or leaks using ultrasonic, infrared, or laser sensors."
                                                    />
                                                </Link>
                                                <Link to="/oilrefienery">
                                                    <InfoCard
                                                        title="Autonomous Underwater Vehicles"
                                                        description="Inspect submerged pipelines or storage tanks."
                                                    />
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="py-3 max-[1000px]:px-1 border-r-[1px] border-r-[#4E4E4E] ">
                                    <div className="px-4 max-lg:px-1">
                                        <div className="py-1">
                                            <div className="px-1">
                                                <div className="px-3">
                                                    <img
                                                        src="../images/navigation/virtual.webp"
                                                        alt=""
                                                        className="py-3"
                                                    />
                                                    <h5
                                                        className="font-[600] py-2 max-lg:pb-1 text-transparent text-[20px] max-lg:text-[18px] leading-[25px]"
                                                        style={{
                                                            background:
                                                                "linear-gradient(90deg, #489DF7 0%, #7637E2 100%)",
                                                            WebkitBackgroundClip:
                                                                "text",
                                                            WebkitTextFillColor:
                                                                "transparent",
                                                        }}
                                                    >
                                                        Virtual Reality Products
                                                    </h5>
                                                </div>
                                                <Link to="/vrbank">
                                                    <InfoCard
                                                        title="VRBank"
                                                        description="Bank seamlessly in an immersive virtual setting with VR Bank."
                                                    />
                                                </Link>
                                                <Link to="/vrauto">
                                                    <InfoCard
                                                        title="VRAuto"
                                                        description="Experience virtual car showrooms like never before in VR Auto."
                                                    />
                                                </Link>
                                                <Link to="/vrworld">
                                                    <InfoCard
                                                        title="VRWorld"
                                                        description="Explore a limitless virtual cityscape in VR World"
                                                    />
                                                </Link>
                                                <Link to="/vrretail">
                                                    <InfoCard
                                                        title="VRRetail"
                                                        description="Shop in a dynamic virtual marketplace with VR Retail."
                                                    />
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="py-3 max-[1000px]:px-1 border-r-[1px] border-r-[#4E4E4E] ">
                                    <div className="px-4 max-lg:px-1">
                                        <div className="py-1">
                                            <div className="px-1">
                                                <div className="px-3">
                                                    <img
                                                        src="../images/navigation/healthcare.webp"
                                                        alt=""
                                                        className="py-3"
                                                    />
                                                    <h5
                                                        className="font-[600] py-2 max-lg:pb-1 text-transparent text-[20px] max-lg:text-[18px] leading-[25px]"
                                                        style={{
                                                            background:
                                                                "linear-gradient(90deg, #489DF7 0%, #7637E2 100%)",
                                                            WebkitBackgroundClip:
                                                                "text",
                                                            WebkitTextFillColor:
                                                                "transparent",
                                                        }}
                                                    >
                                                        Healthcare AI
                                                    </h5>
                                                </div>
                                                <Link to="/healthcare">
                                                    <InfoCard
                                                        title="Medical Imaging SaaS"
                                                        description="Detect diseases in X-rays, MRIs, or CT scans using AI."
                                                    />
                                                </Link>
                                                <Link to="/healthcare">
                                                    <InfoCard
                                                        title="Drug Discovery Platforms"
                                                        description="Use AI to analyze chemical compounds and identify potential drugs."
                                                    />
                                                </Link>
                                                <Link to="/healthcare">
                                                    <InfoCard
                                                        title="Health Monitoring"
                                                        description="Analyze wearable device data for real-time health insights."
                                                    />
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="py-3 max-[1000px]:px-1 border-r-[1px] border-r-[#4E4E4E] ">
                                    <div className="px-4 max-lg:px-1">
                                        <div className="py-1">
                                            <div className="px-1">
                                                <div className="px-3">
                                                    <img
                                                        src="../images/navigation/predictiveanalysis.webp"
                                                        alt=""
                                                        className="py-3"
                                                    />
                                                    <h5
                                                        className="font-[600] py-2 max-lg:pb-1 text-transparent text-[20px] max-lg:text-[18px] leading-[25px]"
                                                        style={{
                                                            background:
                                                                "linear-gradient(90deg, #489DF7 0%, #7637E2 100%)",
                                                            WebkitBackgroundClip:
                                                                "text",
                                                            WebkitTextFillColor:
                                                                "transparent",
                                                        }}
                                                    >
                                                        Predictive Solutions
                                                    </h5>
                                                </div>
                                                <Link to="/predective">
                                                    <InfoCard
                                                        title="IoT-Integrated Platforms"
                                                        description="Use ML to predict equipment failure in manufacturing or energy sectors."
                                                    />
                                                </Link>
                                                <Link to="/predective">
                                                    <InfoCard
                                                        title="Asset Monitoring SaaS"
                                                        description="Analyze sensor data to optimize equipment lifecycle management."
                                                    />
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                //  2024-11-29 18:24:13 => SA done with the navigation
            )}
        </>
    );
}
