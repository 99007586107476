import { IBookDemoViewProps } from "./BookDemo.interfaces";
import Message from "../Message";
export default function BookDemoView(props: IBookDemoViewProps) {
    return (
        <>
            {props.showMessage && (
                <Message
                    messageText={props.messageText}
                    messageClass={props.messageClass}
                    demoController={props.messageController}
                />
            )}
            <div className="fixed left-0 top-0 z-[99999999] h-screen w-full grid items-center justify-center">
                <div
                    className="absolute left-0 top-0 bg-[#00000060] z-[999] w-full h-screen"
                    data-testid="background"
                    onClick={() => props.demoController()}
                ></div>
                <div
                    data-testid="book-demo"
                    className="z-[9999999] h-[650px] rounded-xl max-w-full w-[1200px] max-xl:w-screen max-xl:px-10 max-[1000px]:px-5 max-md:w-screen max-md:h-full max-md:py-4"
                >
                    <div className="grid grid-cols-2 max-md:block border rounded-xl shadow-2xl bg-white max-md:mt-24">
                        <div
                            className="bg-lightblue max-md:hidden p-10 text-left rounded-xl"
                            style={{
                                backgroundImage: `url('../images/navigation/demobackground.webp')`,
                            }}
                        >
                            <h3 className="py-4 font-['roboto'] text-[50px] leading-[60px] text-white">
                                {" "}
                                Discover the Future with a Personalized Demo
                            </h3>

                            <p className="text-[14px] text-white pb-5">
                                Ready to step into a world where imagination
                                meets reality? Book a demo call with us and
                                experience the magic of Invincible Meta
                                firsthand. See how our cutting-edge VR
                                technology can transform your perception of
                                what's possible. Don't just dream about the
                                future – explore it today with Invincible Meta.
                            </p>
                            <div className="flex justify-between py-5 px-10">
                                <div className="rounded-full border-2 border-white w-[30px] h-[30px]"></div>
                                <div className="rounded-full border-2 border-white w-[30px] h-[30px]"></div>
                                <div className="rounded-full border-2 border-white w-[30px] h-[30px]"></div>
                            </div>
                            <img
                                src="../images/randomtoberemoved/demo.png"
                                alt=""
                            />
                        </div>
                        <div className="">
                            <div>
                                <div className="flex justify-end">
                                    <div
                                        className="w-[30px] bg-[#6B6B6B7a] mr-5 mt-5 rounded-full flex justify-center"
                                        onClick={() => props.demoController()}
                                    >
                                        <i className="fa fa-close text-xl text-white cursor-pointer"></i>
                                    </div>
                                </div>
                                <div className="px-4 max-md:px-2 -mt-10">
                                    <img
                                        src="../images/logoshort.webp"
                                        alt=""
                                        className="py-4 max-md:py-2"
                                    />
                                    <h5 className="text-[#1C2347] text-[30px] max-md:text-[27px] leading-[30px] text-left max-md:px-2 pb-2">
                                        Schedule a demo call
                                    </h5>
                                </div>
                                <form className="text-[#1C2347]">
                                    <div className="flex max-md:block">
                                        <div className="w-full px-5 max-lg:px-2">
                                            <label className="text-xs font-medium text-[#1C2347] text-[14px] leading-[16px] font-[500]">
                                                Name
                                                <span className="text-xs text-pink">
                                                    *
                                                </span>
                                            </label>
                                            <input
                                                className="w-full  bg-[#E7E7E7DB] text-sm font-normal border border-grey-500 rounded-md py-3 px-4 max-md:px-2 focus:outline-none"
                                                type="text"
                                                placeholder="Enter name"
                                                onChange={
                                                    props.handleDemoFormChange
                                                }
                                                name="name"
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="">
                                        <div className="w-full px-5 max-lg:px-2">
                                            <label
                                                className="text-xs font-medium text-[#1C2347] text-[14px] leading-[16px] font-[500]"
                                                htmlFor="grid-email"
                                            >
                                                Phone No.
                                                <span className="text-xs text-pink">
                                                    *
                                                </span>
                                            </label>
                                            <input
                                                className="input-number w-full bg-[#E7E7E7DB]  border text-sm font-normal border-grey-500 rounded-md py-3 px-4 max-md:px-2 focus:outline-none"
                                                id="grid-phone"
                                                type="number"
                                                placeholder="Enter Phone No."
                                                onChange={
                                                    props.handleDemoFormChange
                                                }
                                                name="phone-number"
                                                required
                                            />
                                        </div>
                                        <div className="w-full px-5 max-lg:px-2">
                                            <label className="text-xs font-medium text-[#1C2347] text-[14px] leading-[16px] font-[500]">
                                                Company Name
                                                <span className="text-xs text-pink">
                                                    *
                                                </span>
                                            </label>
                                            <input
                                                className="w-full  bg-[#E7E7E7DB] text-sm font-normal border border-grey-500 rounded-md py-3 px-4 max-md:px-2 focus:outline-none"
                                                type="text"
                                                placeholder="Enter Company Name"
                                                onChange={
                                                    props.handleDemoFormChange
                                                }
                                                name="company-name"
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="">
                                        <div className="px-5 max-md:px-2">
                                            <label className="text-xs font-medium text-[#1C2347] text-[14px] leading-[16px] font-[500]">
                                                Business Email
                                                <span className="text-xs text-pink">
                                                    *
                                                </span>
                                            </label>
                                            <input
                                                className="w-full  bg-[#E7E7E7DB] text-sm font-normal border border-grey-500 rounded-md py-3 px-4 max-md:px-2 focus:outline-none"
                                                type="text"
                                                placeholder="Enter Business Email"
                                                onChange={
                                                    props.handleDemoFormChange
                                                }
                                                name="business-email"
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="px-5 max-md:px-2">
                                        <label className="text-xs font-medium text-[#1C2347] text-[14px] leading-[16px] font-[500]">
                                            Message
                                            <span className="text-xs text-pink">
                                                *
                                            </span>
                                        </label>
                                        <textarea
                                            className="w-full py-3 px-4 max-md:px-2 border bg-[#E7E7E7DB] border-grey-500 rounded-md h-[100px] max-md:h-[50px] text-sm focus:outline-none"
                                            placeholder="Enter message here"
                                            required
                                            onChange={
                                                props.handleDemoFormChange
                                            }
                                            name="message"
                                        />
                                    </div>
                                    <div className="px-4 w-full">
                                        <button
                                            onClick={props.handleDemoFormSubmit}
                                            className="bg-blue-100 w-full font-[700] py-3 my-5 text-white transition duration-300 ease-in-out hover:scale-[1.05] hover:bg-gradient-to-l rounded-md mr-4 bg-gradient-to-r from-blue-500 to-purple-600"
                                        >
                                            Submit request
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
