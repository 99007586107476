import { Route, Routes, Navigate } from "react-router-dom";
import { Suspense } from "react";

import "./App.css";

import {
    Aboutus,
    AllUserDetails,
    Branch,
    Contact,
    Dashboard,
    DashboardLogin,
    Faq,
    Industries,
    LandingPage,
    LoginWithCode,
    Metaverse,
    MyAccount,
    NotFound,
    OilRefienery,
    SuperAdminBookedDemos,
    SuperAdminDashboard,
    SuperAdminDashboardUsers,
    SuperAdminDashboardUser,
    SuperAdminLogin,
    SuperAdminMetaverseDetails,
    SuperAdminMetaverseUser,
    SuperAdminMetaverseUsers,
    SuperAdminMyAccount,
    UserDetails,
    VrAuto,
    VrBank,
    VrRetail,
    VrWorld,
    DashboardMetaverseDetails,
    DashboadMetaverseUser,
    DashboadMetaverseUsers,
    HealthCare,
    Predective,
} from "./pages";

import { DashboardLayout, SuperAdminDashboardLayout } from "./Layouts";
import { SessionService } from "./Services";
import { Loader } from "./Components";

function App() {
    const DashboardPrivateRouteWrapper = ({ element }: { element: any }) => {
        const isUserAuthenticated = () => {
            if (
                SessionService.get("accessToken") != null &&
                SessionService.get("user-type") === "dashboard-user"
            ) {
                return true;
            } else {
                return false;
            }
        };
        if (!isUserAuthenticated()) {
            return <Navigate to="/dashboard/login" />;
        }
        return element;
    };

    const SuperAdminPrivateRouteWrapper = ({ element }: { element: any }) => {
        const isUserAuthenticated = () => {
            if (
                SessionService.get("accessToken") != null &&
                SessionService.get("user-type") === "super-admin"
            ) {
                return true;
            } else {
                return false;
            }
        };

        if (!isUserAuthenticated()) {
            return <Navigate to="/super-admin/login" />;
        }
        return element;
    };

    return (
        <div className="App">
            <Routes>
                <Route path="/" element={<Page page={<LandingPage />} />} />
                <Route
                    path="/dashboard/login"
                    element={
                        <Suspense fallback={<Loader />}>
                            <DashboardLogin />
                        </Suspense>
                    }
                />
                <Route
                    path="/super-admin/login"
                    element={
                        <Suspense fallback={<Loader />}>
                            <SuperAdminLogin />
                        </Suspense>
                    }
                />

                <Route
                    path="/contactus"
                    element={<Page page={<Contact />} />}
                />
                <Route
                    path="/metaverse/:id"
                    element={<Page page={<Metaverse />} />}
                />
                <Route path="/faq" element={<Page page={<Faq />} />} />
                <Route path="/aboutus" element={<Page page={<Aboutus />} />} />
                <Route path="/vrauto" element={<Page page={<VrAuto />} />} />
                <Route path="/vrbank" element={<Page page={<VrBank />} />} />
                <Route
                    path="/vrretail"
                    element={<Page page={<VrRetail />} />}
                />
                <Route path="/vrworld" element={<Page page={<VrWorld />} />} />
                <Route
                    path="/oilrefienery"
                    element={<Page page={<OilRefienery />} />}
                />
                <Route
                    path="/healthcare"
                    element={<Page page={<HealthCare />} />}
                />
                <Route
                    path="/predective"
                    element={<Page page={<Predective />} />}
                />
                <Route
                    path="/industries"
                    element={<Page page={<Industries />} />}
                />
                <Route
                    path="/dashboard/home"
                    element={
                        <DashboardPrivateRouteWrapper
                            element={
                                <DashboardLayout>
                                    <Suspense fallback={<Loader />}>
                                        <Dashboard />
                                    </Suspense>
                                </DashboardLayout>
                            }
                        />
                    }
                />
                <Route
                    path="/dashboard/branch"
                    element={
                        <DashboardPrivateRouteWrapper
                            element={
                                <DashboardLayout>
                                    <Suspense fallback={<Loader />}>
                                        <Branch />
                                    </Suspense>
                                </DashboardLayout>
                            }
                        />
                    }
                />
                <Route
                    path="/dashboard/myaccount"
                    element={
                        <DashboardPrivateRouteWrapper
                            element={
                                <DashboardLayout>
                                    <Suspense fallback={<Loader />}>
                                        <MyAccount />
                                    </Suspense>
                                </DashboardLayout>
                            }
                        />
                    }
                />
                <Route
                    path="/dashboard/all-user-details"
                    element={
                        <DashboardPrivateRouteWrapper
                            element={
                                <DashboardLayout>
                                    <Suspense fallback={<Loader />}>
                                        <AllUserDetails />
                                    </Suspense>
                                </DashboardLayout>
                            }
                        />
                    }
                />
                <Route
                    path="/dashboard/user-details"
                    element={
                        <DashboardPrivateRouteWrapper
                            element={
                                <DashboardLayout>
                                    <Suspense fallback={<Loader />}>
                                        <UserDetails />
                                    </Suspense>
                                </DashboardLayout>
                            }
                        />
                    }
                />
                <Route
                    path="/dashboard/metaverse/:id"
                    element={
                        <DashboardPrivateRouteWrapper
                            element={
                                <DashboardLayout>
                                    <Suspense fallback={<Loader />}>
                                        <DashboardMetaverseDetails />
                                    </Suspense>
                                </DashboardLayout>
                            }
                        />
                    }
                />
                <Route
                    path="/dashboard/metaverse-users"
                    element={
                        <DashboardPrivateRouteWrapper
                            element={
                                <DashboardLayout>
                                    <Suspense fallback={<Loader />}>
                                        <DashboadMetaverseUsers />
                                    </Suspense>
                                </DashboardLayout>
                            }
                        />
                    }
                />
                <Route
                    path="/dashboard/metaverse-user/:id"
                    element={
                        <DashboardPrivateRouteWrapper
                            element={
                                <DashboardLayout>
                                    <Suspense fallback={<Loader />}>
                                        <DashboadMetaverseUser />
                                    </Suspense>
                                </DashboardLayout>
                            }
                        />
                    }
                />
                <Route
                    path="/super-admin/home"
                    element={
                        <SuperAdminPrivateRouteWrapper
                            element={
                                <SuperAdminDashboardLayout>
                                    <Suspense fallback={<Loader />}>
                                        <SuperAdminDashboard />
                                    </Suspense>
                                </SuperAdminDashboardLayout>
                            }
                        />
                    }
                />
                <Route
                    path="/super-admin/myaccount"
                    element={
                        <SuperAdminPrivateRouteWrapper
                            element={
                                <SuperAdminDashboardLayout>
                                    <Suspense fallback={<Loader />}>
                                        <SuperAdminMyAccount />
                                    </Suspense>
                                </SuperAdminDashboardLayout>
                            }
                        />
                    }
                />
                <Route
                    path="/super-admin/metaverse/:id"
                    element={
                        <SuperAdminPrivateRouteWrapper
                            element={
                                <SuperAdminDashboardLayout>
                                    <Suspense fallback={<Loader />}>
                                        <SuperAdminMetaverseDetails />
                                    </Suspense>
                                </SuperAdminDashboardLayout>
                            }
                        />
                    }
                />
                <Route
                    path="/super-admin/dashboard-users"
                    element={
                        <SuperAdminPrivateRouteWrapper
                            element={
                                <SuperAdminDashboardLayout>
                                    <Suspense fallback={<Loader />}>
                                        <SuperAdminDashboardUsers />
                                    </Suspense>
                                </SuperAdminDashboardLayout>
                            }
                        />
                    }
                />
                <Route
                    path="/super-admin/bookeddemos"
                    element={
                        <SuperAdminPrivateRouteWrapper
                            element={
                                <SuperAdminDashboardLayout>
                                    <Suspense fallback={<Loader />}>
                                        <SuperAdminBookedDemos />
                                    </Suspense>
                                </SuperAdminDashboardLayout>
                            }
                        />
                    }
                />
                <Route
                    path="/super-admin/dashboard-user/:id"
                    element={
                        <SuperAdminPrivateRouteWrapper
                            element={
                                <SuperAdminDashboardLayout>
                                    <Suspense fallback={<Loader />}>
                                        <SuperAdminDashboardUser />
                                    </Suspense>
                                </SuperAdminDashboardLayout>
                            }
                        />
                    }
                />
                <Route
                    path="/super-admin/metaverse-users"
                    element={
                        <SuperAdminPrivateRouteWrapper
                            element={
                                <SuperAdminDashboardLayout>
                                    <Suspense fallback={<Loader />}>
                                        <SuperAdminMetaverseUsers />
                                    </Suspense>
                                </SuperAdminDashboardLayout>
                            }
                        />
                    }
                />
                <Route
                    path="/super-admin/metaverse-user/:id"
                    element={
                        <SuperAdminPrivateRouteWrapper
                            element={
                                <SuperAdminDashboardLayout>
                                    <Suspense fallback={<Loader />}>
                                        <SuperAdminMetaverseUser />
                                    </Suspense>
                                </SuperAdminDashboardLayout>
                            }
                        />
                    }
                />
                <Route
                    path="/code"
                    element={<Page page={<LoginWithCode />} />}
                />
                <Route path="*" element={<NotFound />} />
            </Routes>
        </div>
    );
}

function Page(props: any) {
    return <Suspense fallback={<Loader />}>{props.page}</Suspense>;
}

export default App;
