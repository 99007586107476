import React from "react";

interface InfoCardProps {
    title: string;
    description: string;
}

const InfoCard: React.FC<InfoCardProps> = ({ title, description }) => {
    return (
        <div className="rounded-md py-3 hover:bg-[#6091E23B] hover:rounded-lg px-3 group duration-300 cursor-pointer">
            <h5 className="group-hover:text-[#6091E2] text-white text-[16px] font-[600]">
                <i className="fa fa-caret-right pr-1" aria-hidden="true"></i>
                {title}
                <i
                    className="fa fa-long-arrow-right pl-5 hidden group-hover:inline"
                    aria-hidden="true"
                ></i>
            </h5>
            <p className="font-[300] text-[12px] text-[#d9d9d9] group-hover:text-[#6A7485] pt-2 max-lg:text-[12px] leading-[18px]">
                {description}
            </p>
        </div>
    );
};

export default InfoCard;
