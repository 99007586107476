import { Link } from "react-router-dom";
import { SuperAdminMetaverseUsersViewProps } from "./SuperAdminMetaverseUsers.interface";
import Message from "../Message";

export default function SuperAdminMetaverseUsersView(
    props: SuperAdminMetaverseUsersViewProps
) {
    return (
        <>
            {props.showMessage && (
                <Message
                    messageText={props.messageText}
                    messageClass={props.messageClass}
                    demoController={props.messageController}
                />
            )}
            <div>
                <h5 className="font-[700] text-[36px] p-3 font-['roboto'] text-[#808080] my-5 max-md:my-0">
                    Metaverse Users
                </h5>
                <div className="superadmin-metaverse-user-box">
                    <div className="superadmin-metaverse-user-head grid-cols-[50px_1fr_1fr_1fr_1fr_1fr_1fr_1fr]">
                        <p>#</p>
                        {/* <p>User ID</p> */}
                        <p>Name</p>
                        <p>Email</p>
                        <p>Phone Number</p>
                        <p>Created At</p>
                        <p>Updated At</p>
                        <p>Delete User</p>
                        <p>View Details</p>
                    </div>
                    {props.users.length >= 1 ? (
                        <>
                            {props.users
                                .slice(props.startIndex, props.endIndex)
                                .map((user: any, index: number) => (
                                    <div
                                        key={index}
                                        className="superadmin-metaverse-user-body grid-cols-[50px_1fr_1fr_1fr_1fr_1fr_1fr_1fr]"
                                    >
                                        <p>
                                            <span>#</span>{" "}
                                            {props.currentPage === 1
                                                ? index + 1
                                                : (props.currentPage - 1) * 10 +
                                                  index +
                                                  1}
                                        </p>
                                        {/* <p>
                                        <span>User ID:</span> {user._id}
                                    </p> */}
                                        <p
                                            className={`${
                                                user.name
                                                    ? "text-black"
                                                    : "text-[#A9A9A9]"
                                            }
                                    } text-[14px]`}
                                        >
                                            <span>Name:</span>{" "}
                                            {user.name
                                                ? user.name
                                                : "Name not Found"}
                                        </p>
                                        <p
                                            className={`${
                                                user.email
                                                    ? "text-black"
                                                    : "text-[#A9A9A9]"
                                            }
                                    }text-[14px]`}
                                        >
                                            <span>Email:</span>
                                            {user.email
                                                ? user.email
                                                : "Email not Found"}
                                        </p>
                                        <p
                                            className={`${
                                                user.phoneNumber
                                                    ? "text-black"
                                                    : "text-[#A9A9A9]"
                                            }
                                    } text-[14px]`}
                                        >
                                            <span>Phone Number:</span>
                                            {user.phoneNumber
                                                ? user.phoneNumber
                                                : "Phone no. not found"}
                                        </p>
                                        <p className="text-[14px]">
                                            <span>Created At:</span>
                                            {new Date(
                                                user.createdAt
                                            ).toLocaleString()}
                                        </p>
                                        <p className="text-[14px]">
                                            <span>Updated At:</span>
                                            {new Date(
                                                user.updatedAt
                                            ).toLocaleString()}
                                        </p>
                                        <p
                                            className="text-red-500 text-[14px] underline font-[600] hover:text-green-500 cursor-pointer  hover:scale-110 duration-300"
                                            onClick={() =>
                                                props.deleteMetaverseUser(
                                                    user._id
                                                )
                                            }
                                        >
                                            Delete User
                                        </p>
                                        <p className="text-[#489BF6] text-[14px] underline font-[600]">
                                            <Link
                                                to={`/super-admin/metaverse-user/${user._id}`}
                                            >
                                                View Details
                                            </Link>
                                        </p>
                                    </div>
                                ))}
                            {
                                <div>
                                    <button
                                        onClick={props.handlePrevPage}
                                        disabled={props.currentPage === 1}
                                        className={`${
                                            props.currentPage === 1
                                                ? "bg-[#DCDCDC]"
                                                : "bg-[#708090]"
                                        } rounded-full text-black px-4 m-2`}
                                    >
                                        <i className="text-black font-[600] fa fa-angle-double-left"></i>
                                    </button>
                                    <span className="font-[600] text-[18px] px-3">
                                        {props.currentPage}
                                    </span>
                                    <button
                                        onClick={props.handleNextPage}
                                        disabled={
                                            props.endIndex >= props.users.length
                                        }
                                        className={`${
                                            props.endIndex >= props.users.length
                                                ? "bg-[#DCDCDC]"
                                                : "bg-[#708090]"
                                        } rounded-full text-black px-4 m-2`}
                                    >
                                        <i className="text-black font-[600] fa fa-angle-double-right"></i>
                                    </button>
                                </div>
                            }
                        </>
                    ) : (
                        <div className="text-center p-10">
                            <p>
                                <span className="font-[600] text-[18px]">
                                    No Users available
                                </span>
                            </p>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}
