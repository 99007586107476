import { Link } from "react-router-dom";
import { SuperAdminBookedDemosViewProps } from "./SuperAdminBookedDemos.interface";

export default function SuperAdminBookedDemosView(
    props: SuperAdminBookedDemosViewProps
) {
    return (
        <div>
            <h5 className="font-[700] text-[36px] p-3 font-['roboto'] text-[#808080] my-5 max-md:my-0">
                Booked Demo's
            </h5>
            <div className="superadmin-metaverse-user-box">
                <div className="superadmin-metaverse-user-head grid-cols-[100px_1fr_1fr_1fr_1fr_1fr_1fr_200px]">
                    <p>#</p>
                    <p>Name</p>
                    <p>Email</p>
                    <p>Phone Number</p>
                    <p>Company Name</p>
                    <p>Message</p>
                    <p>Created At</p>
                    <p>View Details</p>
                </div>
                {props.users.length >= 1 ? (
                    <>
                        {props.users
                            .slice(props.startIndex, props.endIndex)
                            .map((user: any, index: number) => (
                                <div
                                    key={index}
                                    className="superadmin-metaverse-user-body grid-cols-[100px_1fr_1fr_1fr_1fr_1fr_1fr_200px]"
                                >
                                    <p>
                                        <span>#</span>{" "}
                                        {props.currentPage === 1
                                            ? index + 1
                                            : (props.currentPage - 1) * 10 +
                                              index +
                                              1}
                                    </p>
                                    <p>
                                        <span>Name</span> {user.name}
                                    </p>
                                    <p
                                        className={`${
                                            user.name
                                                ? "text-black"
                                                : "text-[#A9A9A9]"
                                        }
                                    }`}
                                    >
                                        <span>Buisness Email:</span>{" "}
                                        {user.email
                                            ? user.email
                                            : "Name not Found"}
                                    </p>
                                    <p
                                        className={`${
                                            user.phoneNumber
                                                ? "text-black"
                                                : "text-[#A9A9A9]"
                                        }
                                    }`}
                                    >
                                        <span>Phone Number :</span>
                                        {user.phoneNumber
                                            ? user.phoneNumber
                                            : "Email not Found"}
                                    </p>
                                    <p
                                        className={`${
                                            user.companyName
                                                ? "text-black"
                                                : "text-[#A9A9A9]"
                                        }
                                    }`}
                                    >
                                        <span>Company Name:</span>
                                        {user.companyName
                                            ? user.companyName
                                            : "Phone no. not found"}
                                    </p>
                                    <p className="text-[14px]">
                                        <span>Message:</span>
                                        {user.message
                                            ? user.message
                                            : "Not Found"}
                                    </p>
                                    <p className="text-[14px]">
                                        <span>Created At:</span>
                                        {new Date(
                                            user.createdAt
                                        ).toLocaleString()}
                                    </p>
                                    <p className="text-[#489BF6] underline font-[600]">
                                        <Link
                                            to={`/super-admin/metaverse-user/${user._id}`}
                                        >
                                            View Details
                                        </Link>
                                    </p>
                                </div>
                            ))}
                        {
                            <div>
                                <button
                                    onClick={props.handlePrevPage}
                                    disabled={props.currentPage === 1}
                                    className={`${
                                        props.currentPage === 1
                                            ? "bg-[#DCDCDC]"
                                            : "bg-[#708090]"
                                    } rounded-full text-black px-4 m-2`}
                                >
                                    <i className="text-black font-[600] fa fa-angle-double-left"></i>
                                </button>
                                <span className="font-[600] text-[18px] px-3">
                                    {props.currentPage}
                                </span>
                                <button
                                    onClick={props.handleNextPage}
                                    disabled={
                                        props.endIndex >= props.users.length
                                    }
                                    className={`${
                                        props.endIndex >= props.users.length
                                            ? "bg-[#DCDCDC]"
                                            : "bg-[#708090]"
                                    } rounded-full text-black px-4 m-2`}
                                >
                                    <i className="text-black font-[600] fa fa-angle-double-right"></i>
                                </button>
                            </div>
                        }
                    </>
                ) : (
                    <div className="text-center p-10">
                        <p>
                            <span className="font-[600] text-[18px]">
                                No Users available
                            </span>
                        </p>
                    </div>
                )}
            </div>
        </div>
    );
}
