import { useEffect, useState, useContext } from "react";
import {
    IBookDemoForm,
    ILandingPageContainerProps,
    IValidationErrors,
} from "./LandingPage.interfaces";
import LandingPageView from "./LandingPage.view";
import BroswerService from "../../Services/Browser.service";
import { Auth } from "../../providers";
import { MetaverseUserService } from "../../Services";
export default function LandingPageContainer(
    props: ILandingPageContainerProps
) {
    const [showlogin, setShowlogin] = useState(false);
    const loginController = () => setShowlogin(!showlogin);
    const [showDemo, setShowDemo] = useState(false);
    const demoController = () => setShowDemo(!showDemo);
    const user = useContext(Auth.Context) ?? null;
    const [days, setDays] = useState(0);
    const [hours, setHours] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);
    const countdownDate = new Date("2024-03-03T00:00:00").getTime();
    const countdown = setInterval(function () {
        const now = new Date().getTime();
        const distance = countdownDate - now;
        setDays(Math.floor(distance / (1000 * 60 * 60 * 24)));
        setHours(
            Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
        );
        setMinutes(Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)));
        setSeconds(Math.floor((distance % (1000 * 60)) / 1000));
        if (distance < 0) {
            clearInterval(countdown);
        }
    }, 1000);
    const [demoForm, setDemoForm] = useState<IBookDemoForm>({
        businessEmail: "",
        companyName: "",
        message: "",
        name: "",
        phoneNumber: "",
    });
    const handleDemoFormChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setDemoForm({
            ...demoForm,
            [event.target.name.replace(/-([a-z])/g, (_, letter) =>
                letter.toUpperCase()
            )]: event.target.value,
        });
    };
    const handleDemoFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        bookDemo(demoForm);
    };
    const validateDemoForm = (demoForm: IBookDemoForm): IValidationErrors => {
        const errors: IValidationErrors = {};
        if (!demoForm?.businessEmail) {
            errors.businessEmail = "Business email is required";
        }
        if (!demoForm?.companyName) {
            errors.companyName = "Company name is required";
        }

        if (!demoForm?.message) {
            errors.message = "Message is required";
        }

        if (!demoForm?.name) {
            errors.name = "Name is required";
        }

        if (!demoForm?.phoneNumber) {
            errors.phoneNumber = "Phone number is required";
        }

        return errors;
    };
    const bookDemo = async (demoForm: any) => {
        const errors = validateDemoForm(demoForm);
        if (Object.keys(errors).length > 0) {
            setShowMessage(true);
            setMessageClass("error");
            setMessageText("Please fill all the fields");
            setTimeout(() => {
                setShowMessage(false);
            }, 2000);
            return;
        }
        if (!/\S+@\S+\.\S+/.test(demoForm.businessEmail)) {
            setShowMessage(true);
            setMessageClass("error");
            setMessageText("Invalid email address!");
            setTimeout(() => {
                setShowMessage(false);
            }, 2000);
            return;
        }
        if (!/^\d{10}$/.test(demoForm.phoneNumber)) {
            setShowMessage(true);
            setMessageClass("error");
            setMessageText("Invalid Phone Number!");
            setTimeout(() => {
                setShowMessage(false);
            }, 2000);
            return;
        }
        const data = await MetaverseUserService.bookDemo(
            demoForm.businessEmail,
            demoForm.companyName,
            demoForm.message,
            demoForm.name,
            demoForm.phoneNumber
        );
        if (data && data?.message === "Successully sent email.") {
            setShowMessage(true);
            setMessageClass("success");
            setMessageText("Demo booked successfully");
            setTimeout(function () {
                window.location.reload();
            }, 5000);
        } else {
            setShowMessage(true);
            setMessageClass("error");
            setMessageText(data?.response?.data?.errors?.details[0]?.message);
        }
    };
    const [messageText, setMessageText] = useState("");
    const [messageClass, setMessageClass] = useState("");
    const [showMessage, setShowMessage] = useState(false);
    const messageController = () => setShowMessage(!showMessage);
    useEffect(() => {
        BroswerService.setCustomTitle("LandingPageContainer");
    }, []);
    return (
        <>
            <LandingPageView
                demoController={demoController}
                showDemo={showDemo}
                days={days}
                hours={hours}
                minutes={minutes}
                seconds={seconds}
                loginController={loginController}
                showlogin={showlogin}
                user={user}
                handleDemoFormChange={handleDemoFormChange}
                handleDemoFormSubmit={handleDemoFormSubmit}
                messageController={messageController}
                messageText={messageText}
                messageClass={messageClass}
                showMessage={showMessage}
            />
        </>
    );
}
