import SuperAdminDashboardUserView from "./SuperAdminDashboardUser.view";
import { SuperAdminDashboardUserContainerProps } from "./SuperAdminDashboardUser.interfaces";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { DashboardUserService, MetaverseService } from "../../Services";

export default function SuperAdminDashboardUserContainer(
    props: SuperAdminDashboardUserContainerProps
) {
    const dashboardUserID = useParams<{ id: string }>().id;
    const [dashboardUser, setDashboardUser] = useState<any>({});
    const [metaverses, setMetaverses] = useState<any>([]);
    const [selectedMetaverse, setSelectedMetaverse] = useState<any>({});

    useEffect(() => {
        async function fetchDashboardUser() {
            const _dashboardUser = await DashboardUserService.fetchById(
                dashboardUserID as string
            );
            setDashboardUser(_dashboardUser.data.dashboardUser);
        }
        fetchDashboardUser();
        async function fetchMetaversesDashboardUserManages() {
            const metaverses =
                await DashboardUserService.fetchMetaversesByDashboardUserId(
                    dashboardUserID as string
                );
            setDashboardUser((_: any) => {
                return {
                    ..._,
                    metaverses: metaverses.data?.metaverses ?? [],
                };
            });
        }
        fetchMetaversesDashboardUserManages();
        const fetchMetaveses = async () => {
            const metaverses = await MetaverseService.fetch();
            setMetaverses(metaverses.data.metaverses);
            setSelectedMetaverse(metaverses.data.metaverses[0]._id);
        };
        fetchMetaveses();
    }, [dashboardUserID]);

    const addDashboardUserToMetaverse = async () => {
        // const response =
        await MetaverseService.addDashboardUserAsMetaverseManager(
            selectedMetaverse,
            dashboardUserID as string
        );
        // if(response.message)
        // alert("Dashboard user set as a metaverse manager.");
        setShowMessage(true);
        setMessageText("Dashboard user set as a metaverse manager.");
        setMessageClass("success");
        setTimeout(function () {
            setShowMessage(false);
            window.location.reload();
        }, 2000);
    };

    const selectMetaverse = async (e: any) => {
        setSelectedMetaverse(e.target.value);
    };
    const [messageText, setMessageText] = useState("");
    const [messageClass, setMessageClass] = useState("");
    const [showMessage, setShowMessage] = useState(false);
    const messageController = () => setShowMessage(!showMessage);
    return (
        <div>
            <SuperAdminDashboardUserView
                selectMetaverse={selectMetaverse}
                dashboardUser={dashboardUser}
                metaverses={metaverses}
                addDashboardUserToMetaverse={addDashboardUserToMetaverse}
                messageController={messageController}
                messageText={messageText}
                messageClass={messageClass}
                showMessage={showMessage}
            />
        </div>
    );
}
