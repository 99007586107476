import { useState } from "react";
import {
    IBookDemoContainerProps,
    IBookDemoForm,
    IValidationErrors,
} from "./BookDemo.interfaces";
import BookDemoView from "./BookDemo.view";
import { MetaverseUserService } from "../../Services";

export default function BookDemoContainer(props: IBookDemoContainerProps) {
    const [demoForm, setDemoForm] = useState<IBookDemoForm>({
        businessEmail: "",
        companyName: "",
        message: "",
        name: "",
        phoneNumber: "",
    });

    const handleDemoFormChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setDemoForm({
            ...demoForm,
            [event.target.name.replace(/-([a-z])/g, (_, letter) =>
                letter.toUpperCase()
            )]: event.target.value,
        });
    };
    const validateDemoForm = (demoForm: IBookDemoForm): IValidationErrors => {
        const errors: IValidationErrors = {};
        if (!demoForm?.businessEmail) {
            errors.businessEmail = "Business email is required";
        }
        if (!demoForm?.companyName) {
            errors.companyName = "Company name is required";
        }

        if (!demoForm?.message) {
            errors.message = "Message is required";
        }

        if (!demoForm?.name) {
            errors.name = "Name is required";
        }

        if (!demoForm?.phoneNumber) {
            errors.phoneNumber = "Phone number is required";
        }

        return errors;
    };

    const bookDemo = async (demoForm: any) => {
        const errors = validateDemoForm(demoForm);
        if (Object.keys(errors)?.length > 0) {
            setShowMessage(true);
            setMessageClass("error");
            setMessageText("Please fill all the fields");
            setTimeout(() => {
                setShowMessage(false);
            }, 2000);
            return;
        }
        if (!/\S+@\S+\.\S+/.test(demoForm?.businessEmail)) {
            setShowMessage(true);
            setMessageClass("error");
            setMessageText("Invalid email address!");
            setTimeout(() => {
                setShowMessage(false);
            }, 2000);
            return;
        }
        if (!/^\d{10}$/.test(demoForm?.phoneNumber)) {
            setShowMessage(true);
            setMessageClass("error");
            setMessageText("Invalid Phone Number!");
            setTimeout(() => {
                setShowMessage(false);
            }, 2000);
            return;
        }

        const data = await MetaverseUserService.bookDemo(
            demoForm?.businessEmail,
            demoForm?.companyName,
            demoForm?.message,
            demoForm?.name,
            demoForm?.phoneNumber
        );

        if (data && data?.message === "Successully sent email.") {
            setShowMessage(true);
            setMessageClass("success");
            setMessageText("Request Sent successfully!!");
            setTimeout(function () {
                setShowMessage(false);
                window.location.reload();
            }, 2000);
        } else {
            setShowMessage(true);
            setMessageClass("error");
            setMessageText(data);
            setTimeout(function () {
                setShowMessage(false);
            }, 2000);
        }
    };

    const handleDemoFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        bookDemo(demoForm);
    };

    const [messageText, setMessageText] = useState("");
    const [messageClass, setMessageClass] = useState("");
    const [showMessage, setShowMessage] = useState(false);
    const messageController = () => setShowMessage(!showMessage);

    return (
        <>
            <BookDemoView
                handleDemoFormSubmit={handleDemoFormSubmit}
                handleDemoFormChange={handleDemoFormChange}
                demoController={props.demoController}
                messageController={messageController}
                messageText={messageText}
                messageClass={messageClass}
                showMessage={showMessage}
            />
        </>
    );
}
